@media all and (max-width: 720px){
    .box-img-bg{
        padding: 2rem;
        figcaption{
            .inner-text{
                width: 100%;
            }
        }
    }
    .thumb-consultation{
        // margin-left: -1.7rem;
        .column{
            h5{font-size: 1.5rem;}
            p{font-size: 1.4rem;margin-bottom: 0;}
            > div{
                padding: 2rem 1rem;
            }
            figure{
                align-self: center;
                flex-basis: 10rem;
                max-width: 10rem;
            }
        }
    }
    .faq-section{
        .wrapper-small{
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}