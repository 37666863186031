.thumb-rounded{
	margin-bottom: 2rem;
	.column{
		margin-bottom: 3.8rem !important;

		> div{
			border-radius: .6rem;
			box-shadow: 0 0 .6rem 0 rgba(0,0,0,0.16);
			transition: .2s all ease-out;
			height: 100%;
			position: relative;
		}
		h5{
			font-weight: 700;
			margin-bottom: 0rem;
			color: #111111;
		}
		h6{
			font-size: 1.5rem;
			color: #818181;
			margin-bottom: 1.5rem;
		}
		figure{
			overflow: hidden;
			border-radius: .6rem .6rem 0 0;
			img{
				width: 100%;
				border-radius: .6rem .6rem 0 0;
				transition: .2s all ease-out;
			}
		}
		figcaption{
			padding: 2rem 3rem;
			.button{
				width: auto;
				@include position(absolute, null 3rem 2rem);
			}
		}
		.button{
			margin-bottom: 0;
		}
		&:hover{
			> div{
				box-shadow: 0 0 2rem 0 rgba(0,0,0,0.16);
			}
			figure{
				img{
					transform: scale(1.02);
				}
			}
		}
	}
}

.detail-pustaka{
	figure{
		margin-bottom: 2.4rem;
		img{
			width: 100%;
			border-radius: .6rem;
			box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,0.16);
		}
	}
	h5{
		margin-bottom: 1rem;
		color: #111111;
		
		&.bblue{
			color: #353587;
		}
	}
	.table-no-border{
		td, th {
			color: #111111;
			padding-top: 1rem;
			padding-bottom: 1rem;

			&:first-child{
				color: #888888;
			}
		}
	}
}

.text-date{
	font-weight: 700;
	color: #999999;
	font-size: 1.4rem;
	margin-bottom: .5rem;
	display: block;
}

.thumb-rounded-gallery{
	@extend .slider-activity-home;
	.list{
		margin-top: 0;
		margin-bottom: 3rem !important;
		cursor: pointer;
		> div{
			position: relative;
		}
		figcaption{
			span{
				font-size: 1.4rem;
			}
			p{
				font-size: 1.5rem;
			}
		}
		.content-gallery{
			display: none;
		}
		&:hover{
			transform: scale(1.05);
		}
	}
}

.thumb-rounded-infografis{
	.content-infografis{
		display: none;
	}
}

.slider-gallery{
	margin-bottom: 1.1rem;
	figure{
		img{
			width: 100%;
			border-radius: .6rem;
		}
	}
}

.slider-gallery-thumb{
	position: relative;
	padding: 0 4rem;

	figure{
		margin: 0 .5rem;
		img{
			width: 100%;
			border-radius: .4rem;
			border: .3rem inset transparent;
		}

		&.slick-current{
			img{
				border-color: #363689;
			}
		}
	}
	.slick-track{
		&.noslide{
			transform: translate3d(0px, 0px, 0px) !important;
		}
	}
	.slick-arrow{
		font-size: 0;
		color: transparent;
		padding: 0;
		width: 2.9rem;
		height: 5rem;
		margin: 0;
		border-radius: .4rem;
		background: rgba(193,193,232,.42) url('../images/material/arrow-slide.svg') no-repeat center;
		position: absolute;
		top: 0;

		&.slick-prev{
			transform: rotate(180deg);
			left: 0;
		}
		&.slick-next{
			right: 0;
		}
	}
}

.images-slider{
	position: relative;
	padding-bottom: 8rem;
	margin-bottom: 4rem;
	.slick-arrow{
		background: url('../images/material/arrow-left-blue.svg') no-repeat left;
		border-radius: 0;
		padding: 0;
		margin: -3rem 0 0;
		font-size: 14px;
		color: #6262A5;
		border: none;
		z-index: 2;
		transition: .2s all ease-out;
		padding-left: 20px;
		@include position(absolute, null null 0 null);

		&.slick-next{
			left: auto;
			right: 0;
			padding: 0;
			padding-right: 20px;
			background: url('../images/material/arrow-right-blue.svg') no-repeat right;
		}

		&:hover{
			&.slick-prev{
				padding-left: 2.5rem;
			}
			&.slick-next{
				padding-right: 2.5rem;
			}
		}
	}
	.slick-dots{
		list-style: none;
		display: flex;
		margin: 0;
		padding: 0;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		li{
			margin: 0;
			padding: 0;
			margin: 0 5px;
			button{
				background: #fff;
				width: 14px;
				height: 14px;
				border-radius: 100%;
				border: 2px solid #A5A5A5;
				padding: 0;
				font-size: 0;
				margin: 0;

			}
			&::before{
				display: none;
			}
			&.slick-active{
				button{
					background: #4CBED3;
					border-color: #4CBED3;
				}
			}
		}
	}
}

.info-kliping{
	border-radius: .6rem;
	box-shadow: 0 0 .6rem 0 rgba(0,0,0,0.16);
	padding: 3rem 4rem;
	box-sizing: border-box;
	margin: 4rem 0;
	table{
		margin: 0;
	}
}

.filter-kamus{
	// display: flex;
	// flex-wrap: wrap;
	&.row .column.column-7{
		flex: 0 0 6.5%;
		max-width: 6.5%;
	}
	span{
		font-size: 2rem;
		line-height: 5rem;
		text-align: center;
		color: #111111;
		font-weight: 700;
		width: 100%;
		display: block;
		border: 1px solid #C6C6C6;
		border-radius: 10px;
		// margin-right: 2rem;
		margin-bottom: 2rem;
		transition: .2s all ease-out;
		&:hover{
			background: #4CBED3;
			border-color: #4CBED3;
			cursor: pointer;
			color: #fff;
			transition: .2s all ease-out;
		}
	}
}

.kamus-list{
	.column{
		border-bottom: 1px solid #E2E2E2;
		margin-top: 3rem;
		&:last-child{
			border-bottom: 0;
		}
	}
	h5{
		margin: 0;
		font-size: 1.8rem;
		line-height: 3rem;
		color: #111111;
		font-weight: 600;
	}
	p{
		color: #999999;
		margin-bottom: 4rem;
		span{
			color: #353587;
			font-weight: 700;
			text-decoration: underline;
		}
	}
}

.kamus-content{
	.pagination{
		justify-content: center;
	}
}

.detail-kamus{
	border-radius: .6rem;
	box-shadow: 0 0 .6rem 0 rgba(0,0,0,0.16);
	padding: 4.5rem 3rem;
	box-sizing: border-box;
	.referensi{
		border: 1px solid #E0E0E0;
		border-radius: 6px;
		.title{
			color: #333333;
			background: #F4F4F4;
			padding: 2rem;
			border-radius: 6px 6px 0 0;
		}
		ul{
			list-style: none;
			padding: 2rem;
			margin: 0;
			li{
				padding-left: 0;
				&::before{
					display: none;
				}
				a{
					color: #353587;
					text-decoration: underline;
					font-weight: 500;
				}
			}
		}
	}
}