@media all and (max-width: 720px){
    .banner-home, .banner-home .slider-home figure{
        height: 47rem;
    }
    .banner-home{
        .text-banner{
            max-width: 100%;
            width: 100%;
            padding: 0 2rem;
            bottom: 0;
            transition: .3s all ease-out;
            h3{
                font-size: 2rem;
                margin-bottom: .5rem;
            }
            p{font-size: 1.3rem;}
            &.act{
                bottom: 4.5rem;
                transition: .3s all ease-out;
            }
        }
    }
    .box-search-bar{
        padding: .8rem .7rem;
        &.act{
            &::before{
                height: calc( 100% + 140px);
            }
        }
    }
    .text-search{
        margin-bottom: .8rem;
        input[type=text]{
            height: 5rem;
            font-size: 1.3rem;
            padding-left: 1rem;
            padding-right: 3rem;
        }
        input[type=submit]{
            width: 1.3rem;
            background-size: 100%;
            right: 1rem;
        }
    }
    .select-search{
        select{
            margin-right: 0;
            font-size: 1.2rem;
            width: 100%;
            max-width: 100%;
            flex: 1;
            &:first-child{
                flex: 2;
            }
            &:last-child{
                max-width: 100%;
                flex: 1;
            }
        }
    }
    .box-list-rules{
        flex-wrap: wrap;
        .list{
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 3rem;
            padding-left: 6.8rem;
            padding-right: 0;
            > img{
                left: 0;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .activity-home{
        background-size: cover;
        background-position: bottom right;
        padding-bottom: 8rem;
        .wrapper{
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    .slider-activity-home{
        .list{
            width: 28rem;
        }
        .slick-arrow{
            width: 3rem;
            height: 3rem;
            margin-top: -1.5rem;
            left: 2.2rem;
            &.slick-next{
                left: auto;
                right: 2.2rem;
            }
            &:hover{
                &.slick-prev{
                    left: 2.2rem;
                }
                &.slick-next{
                    right: 2.2rem;
                }
            }
        }
    }
    .news-home{
        padding: 4rem 0;
        .news-list{
            figure{
                flex-basis: 100%;
                max-width: 100%;
                margin-top: .5rem;
            }
            figcaption{
                flex: 1 0 63%;
                max-width: 63%;
                margin-left: 1.5rem;
            }
            .column{
                align-items: flex-start;
                margin-bottom: 2rem;
                border-bottom: .1rem solid #EFEFEF;
                padding-bottom: 1.2rem;
                > a{
                    flex-basis: 35%;
                    max-width: 35%;
                    margin-right: 0;
                }
            }
        }
    }
    .news-list{
        p{font-size: 1.4rem;}
        span{
            &.date{font-size: 1.2rem;}
        }
        figure{
            margin-bottom: 1.5rem;
        }
        .column{
            flex-basis: 100% !important;
            max-width: 100% !important;
        }
        .link{font-size: 1.3rem;}
    }
    .about-home{
        &:after{top: 5rem;}
        h1{
            font-size: 1.8rem;
            &.light{font-size: 1.8rem;}
        }
        .wrapper{
            flex-wrap: wrap;
            padding-left: 2rem;
            padding-right: 2rem;
        }
        figure{
            margin-bottom: 2rem;
        }
        .text-about{
            padding-top: 0;
            flex-basis: 100%;
            max-width: 100%;
        }
    }
    .grafik-home{
        .wrapper{
            flex-wrap: wrap;
            padding-left: 2rem;
        }
        figure, .text-grafik{
            flex-basis: 100%;
            max-width: 100%;
        }
        figure{
            order: 2;
        }
        .text-grafik{
            order: 1;
            margin-bottom: 2rem;
        }
    }
    .survey-home{
        padding: 8rem 0;
        .wrapper{
            padding: 0 2rem;
            flex-wrap: wrap;
        }
        .text-survey, .survey-result{
            flex-basis: 100%;
            max-width: 100%;
        }
        .survey-result{
            .row{
                // flex-direction: row;
                // .column{
                //     &.column-55{
                //         flex-basis: 45%;
                //         max-width: 45%;
                //     }
                //     &.column-45{
                //         flex-basis: 50%;
                //         max-width: 50%;
                //     }
                // }
            }
            .circle-survey{
                width: 28rem;
                height: 28rem;
            }
        }
    }
    .related-link{
        padding-right: 0;
        .wrapper{
            flex-direction: column;
            justify-content: flex-start;
            align-items: normal;
        }
        .right-related-link{
            flex-basis: 100%;
            max-width: 100%;
            padding: 0 2rem;
        }
    }
    .tema-home{
        .slider-thema{
            margin-right: -10px;
            .thumb-rounded{
                &.row{
                    flex-direction: row;
                }
                .column{
                    margin-right: 0px;
                    flex: 0 0 275px !important;
                    max-width: 275px !important;
                    width: 275px;
                    padding: 0 1.0rem;
                    box-sizing: border-box;
                    > div, > div > a{
                        display: block;
                    }
                    figure,figcaption{
                        flex-basis: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
        // .mCSB_horizontal.mCSB_inside > .mCSB_container{flex-flow: wrap;}
    }
    .infografis-home{
        .thumb-rounded{
          margin-right: -20px;
            .column{
                > div, > div > a{
                    display: block;
                }
                figure,figcaption{
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

@media all and (max-width: 620px){
    .survey-home{
        .survey-result{
            .circle-survey{
                width: 25rem;
                height: 25rem;
            }
        }
    }
}
@media all and (max-width: 550px){
    .box-search-bar.act::before {
        height: calc(100% + 280px); background: rgba(18, 29, 54, 1);
    }
}

@media all and (max-width: 460px){
    .survey-home{
        .survey-result{
            .row{
                .column{
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}