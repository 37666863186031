.banner-home{
	position: relative;
	padding: 7.8rem 0 9.5rem;
	height: 43rem;

	// figure{
	// 	@include position(absolute, 0);
	// 	img{
	// 		width: 100%;
	// 		height: 100%;
	// 		object-fit: cover;
	// 	}
	// }
	.slider-home{
		@include position(absolute, 0);
		figure{
			height: 43rem;
			overflow: hidden;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.text-banner{
		position: relative;
		z-index: 2;
		max-width: 86rem;
		width: 86rem;
		margin: 0 auto;
		color: $color-initial;
		text-align: center;

		h3{
			margin-bottom: 1rem;
		}
		p{
			font-size: 1.9rem;
		}
	}
}

.box-search-bar{
	padding: 3rem;
	padding-bottom: 5px;
	position: relative;
	&:before{
		content: "";
		position: absolute;
		background: rgba(18,29,54,.67);
		border-radius: 1rem;
		display: block;
		top: 0;
		left:0;
		height: 100%;
		width: 100%;
		transition: .3s all ease-out;
	}
	&.act{
		&:before{
			height: calc( 100% + 95px );
			transition: .3s all ease-out;
		}
	}
	.text-search{
		margin-bottom: 5px;
	}
}

.text-search{
	position: relative;
	margin-bottom: 1.4rem;

	input[type=text]{
		background: $color-initial;
		padding: 0 5rem 0 3.1rem;
		height: 6rem;
		border-radius: 1rem;
		margin: 0;

		@include andplaceholder{
			color: #939393;
		}
	}
	input[type=submit]{
		padding: 0;
		background: url('../images/material/icon-search-grey.svg')no-repeat center;
		width: 2.4rem;
		height: 2.4rem;
		margin: auto 0;
		border: none;
		@include position(absolute, 0 2rem 0 null);
	}
}

.select-search{
	display: flex;

	select{
		border: none;
		margin:0 3% 0 0;
		padding: 0 2rem 0 1rem;
		width: auto;
		height: 2rem;
		line-height: 2rem;
		font-size: 1.3rem;
		font-weight: 700;
		color: $color-initial;
		background: url('../images/material/arrow-select.svg')no-repeat center right;
		flex: 1 0 18%;
		max-width: 18%;
		option { color: $color-secondary; }
		&:last-child{
			flex: 1 0 14%;
			max-width: 14%;
			margin-right:0;
		}
	}
}

.rules-home{
	padding: 3rem 0;
	border-bottom: .1rem solid #EFEFEF;

	.wrapper{
		display: flex;
		justify-content: space-between;
	}

	.left-rules-home{
		border-right: .1rem solid rgba(54,54,137,.1);
		padding-right: 2rem;
		flex: 1 0 20%;
		max-width: 20%;
	}
    .right-rules-home{
		flex: 1;
	}
}

.box-list-rules{
	display: flex;

	.list{
		flex: 1 0 50%;
		max-width: 50%;
		padding: 0 2rem 0 8.8rem;
		position: relative;
		color: $color-secondary;

		> img{
			@include position(absolute, 0 null null 2rem);
		}
		p{
			font-size: 1.5rem;
			margin-bottom: .5rem;
		}

		&.full{
			flex: 1 0 100%;
			max-width: 100%;
		}
	}
}

.activity-home{
	padding: 7rem 0 16.6rem;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: bottom center;
}

.slider-activity-home{
	position: relative;
	.list{
		width: 39rem;
		position: relative;
		padding: 0 .8rem;
		margin: 2rem 0;
		transition: .3s all ease-out;

		figure{
			position: relative;
			border-radius: 1rem;
			overflow: hidden;

			&:after{
				content:"";
				height: 9rem;
				border-radius: 0 0 1rem 1rem;
				@include position(absolute, null 0 0);
				background: rgba(255,255,255,0);
				background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(0,0,0,0.7)));
				background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7) 100%);
				background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7) 100%);
				background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7) 100%);
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
			}
			img{
				width: 100%;
				border-radius: 1rem;
			}
		}
		figcaption{
			color: $color-initial;
			@include position(absolute, null 2.7rem 3rem);

			span{
				font-weight: 700;
				font-size: 1.1rem;
				display: block;
				margin-bottom: .5rem;
			}
			p{
				margin-bottom: 0;
				overflow: hidden;
				max-height: 4rem;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		.content-gallery{
			display: none;
		}

		&.video-act,&.photo-act{
			figure{
				&:before{
					content:"";
					width: 3rem;
					height: 3rem;
					z-index: 1;
					@include position(absolute, 2rem 0 0 2rem);
				}
			}
		}
		&.video-act{
			figure{
				&:before{
					background: url('../images/material/icon-video.svg')no-repeat center;
				}
			}
		}
		&.photo-act{
			figure{
				&:before{
					background: url('../images/material/icon-image.svg')no-repeat center;
				}
			}
		}

		&:hover{
			transform: scale(1.15);
			z-index: 1;
		}
	}

	.slick-arrow{
		width: 6rem;
		height: 6rem;
		background: url('../images/material/arrow-left-shadow.png') no-repeat center;
		background-size: 100%;
		border-radius: 0;
		padding: 0;
		margin: -3rem 0 0;
		font-size: 0;
		color: transparent;
		border: none;
		z-index: 2;
		transition: .2s all ease-out;
		@include position(absolute, 50% null null 5rem);

		&.slick-next{
			left: auto;
			right: 5rem;
			transform: rotate(180deg);
		}

		&:hover{
			&.slick-prev{
				left: 4.5rem;
			}
			&.slick-next{
				right: 4.5rem;
			}
		}
	}
}

.news-home{
	padding: 8rem 0 2rem;
	background: #F7F7F7;
}

.home-section {
	position: relative;
}

.news-list{
	figure{
		margin-bottom: 2.2rem;
		overflow: hidden;
		border-radius: .6rem;

		img{
			width: 100%;
			border-radius: .6rem;
			transition: ease-out .3s all;
		}

		&:hover{
			img{
				transform: scale(1.05);
			}
		}
	}
	span{
		&.date{
			font-weight: 700;
			color: #999999;
			font-size: 1.4rem;
			margin-bottom: .5rem;
			display: block;
		}
	}
	p{
		font-size: 1.5rem;
		margin-bottom: 1rem;
		color: $color-secondary;
	}
	.column{
		margin-bottom: 4rem;
	}
}

.about-home{
	position: relative;
	color: $color-initial;
	&:before{
		content: "";
		background: #F7F7F7;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -99;
	}
	&:after{
		content:"";
		background: rgba(73,176,202,1);
		background: -moz-linear-gradient(-45deg, rgba(73,176,202,1) 0%, rgba(46,46,121,1) 50%, rgba(46,46,121,1) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(73,176,202,1)), color-stop(50%, rgba(46,46,121,1)), color-stop(100%, rgba(46,46,121,1)));
		background: -webkit-linear-gradient(-45deg, rgba(73,176,202,1) 0%, rgba(46,46,121,1) 50%, rgba(46,46,121,1) 100%);
		background: -o-linear-gradient(-45deg, rgba(73,176,202,1) 0%, rgba(46,46,121,1) 50%, rgba(46,46,121,1) 100%);
		background: -ms-linear-gradient(-45deg, rgba(73,176,202,1) 0%, rgba(46,46,121,1) 50%, rgba(46,46,121,1) 100%);
		background: linear-gradient(135deg, rgba(73,176,202,1) 0%, rgba(46,46,121,1) 50%, rgba(46,46,121,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49b0ca', endColorstr='#2e2e79', GradientType=1 );
		min-height: 50rem;
		z-index: -1;
		@include position(absolute, 20rem 0 0);
	}
	h1{
		color: $color-initial;
		font-size: 2.4rem;
		letter-spacing: -.08rem;
		line-height: 1.35;
		font-weight: 700;
		margin-bottom: 1rem;
		&.light{
			font-weight: 300;
			font-size: 2.3rem;
			color: $color-initial;
		}
	}
	h4{color: $color-initial;}
	p{
		color: #9393CE;
		font-size: 1.5rem;
		line-height: 2.4rem;
	}
	.wrapper{
		display: flex;
		align-items: center;
	}
	.text-about{
		flex: 1 0 50%;
		max-width: 50%;
		padding: 20rem 0 2rem;
	}
}

.grafik-home{
	padding: 6.3rem 0;

	p{
		font-size: 1.5rem;
		line-height: 2.4rem;
		color: #818181;
	}
	.wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 107rem;
	}
	.text-grafik{
		flex: 1 0 50%;
		max-width: 50%;
	}
	.highcharts-data-table {
		caption {
			font-size: 1.2em;
		}
	}
	.highcharts-menu{
		.highcharts-menu-item{
			margin-bottom: 0;
		}
		hr{margin: 0;}
	}
}

.survey-home{
	background: #F7F7F7;
	padding: 8.8rem 0 9.8rem;

	p{
		font-size: 1.5rem;
		line-height: 2.4rem;
		color: #818181;
	}
	.button{
		border-radius: 30px;
	}
	.wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 97rem;
	}
	.text-survey{
		flex: 1 0 50%;
		max-width: 50%;
	}
	.survey-result{
		flex: 1 0 48%;
		max-width: 48%;
		h6{
			font-size: 1.5rem;
			font-weight: 600;
			color: #4CBED3;
			text-transform: uppercase;
		}
	}
	.lap-row{
		margin-bottom: 1.5rem;
		span{
			display: block;
			font-weight: 600;
			font-size: 1.1rem;
			color: #818181;
			text-transform: uppercase;
		}
		h3{margin-bottom: 0;}
		p{
			font-size: 1.4rem;
			line-height: 1.9rem;
			font-weight: 700;
			color: #111111;
			margin-bottom: 0;
		}
	}
}

.circle-survey{
	width: 24.2rem;
	height: 24.2rem;
	background: url('../images/material/bg-circle-survey.png')no-repeat center;
	background-size: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h2{
		font-size: 6.3rem;
		font-weight: 700;
		margin-bottom: -.5rem;
		span{
			&.small{
				font-size: 2.8rem;
			}
		}
	}
	p{
		font-size: 1.5rem;
		font-weight: 600;
		color: #818181;
		margin-bottom: 0;
	}
	.stars{
		margin: 0;
		li{
			display: inline-block; position: relative;
			margin: 0;
			filter: grayscale(1);
			img {display: block;}
			&.active{
				filter: grayscale(0);
			}
			&.half {
				&::after {
					content: ''; position: absolute; left: auto; top: 0; bottom: 0; right: 0;
					display: block; width: 50%; filter: grayscale(1); overflow: hidden;
					background: url('../images/material/ico-stars.svg') no-repeat right center;
				}
			}
		}
	}
}

.progress-bar{
	.list{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		h6{
			margin: 0;
			color: #818181;
			font-size: 1.5rem;
		}
	}
	.progress {
		padding:0;
		width:80%;
		height:8px;
		overflow:hidden;
		background:#e5e5e5;
		border-radius:6px;
		margin-left: 20px;
	}

	.bar {
		position:relative;
		float:left;
		min-width:1%;
		height:100%;
		background: rgb(53,53,135);
		background: linear-gradient(90deg, rgba(53,53,135,1) 0%, rgba(75,183,207,1) 79%);
	}
}

.related-link{
	padding: 3.4rem 0 4.5rem;

	.wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.right-related-link{
		flex: 1 0 60%;
		max-width: 60%;
	}
}
.slider-related-link{
	position: relative;

	.list{
		text-align: center;
		img{
			display: inline-block;
			filter: grayscale(100%);
		}

		&:hover, &.slick-current{
			img{
				filter: grayscale(0%);
			}
		}
	}
	.slick-arrow{
		position: absolute;
		top: 50%;
		margin-top: -1.4rem;
		width: 2.8rem;
		height: 2.8rem;
		border-radius: 50%;
		padding: 0;
		font-size: 0;
		color: transparent;
		box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,0.16);
		z-index: 1;
		background: $color-initial url('../images/material/arrow-slide-small.svg')no-repeat center;

		&.slick-prev{
			left: -3rem;
			transform: rotate(180deg);
			box-shadow: 0 -.3rem .6rem 0 rgba(0,0,0,0.16);
		}
		&.slick-next{
			right: -3rem;
		}
	}
}

.trig{
	@include position(absolute, 40% null null);
}

.tema-home{
	margin-top: 6rem;
	.slider-thema{
    margin-right: calc(((100vw - 1300px) / 2) * -1);
    .row{
      flex-wrap: nowrap;
      overflow-x: auto;
      /* width */
      &::-webkit-scrollbar {
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #353587; 
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #353587; 
      }
    }
		.thumb-rounded{
			.column{
        width: 308px;
        flex: 0 0 308px;
        max-width: 308px;
			}
		}
	}
	.mCSB_horizontal.mCSB_inside > .mCSB_container{
		display: flex;
		flex-wrap: wrap;
	}
	.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background: #353587; 
	}
	.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
		height: 4px; 
	}
}

.infografis-home{
  margin-top: 6rem;
  .row{
    flex-flow: row nowrap;
    overflow-x: auto;
    /* width */
    &::-webkit-scrollbar {
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #353587; 
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #353587; 
    }
  }
	.thumb-rounded{
		.column{
      // width: 308px;
      // flex: 0 0 308px;
      // max-width: 308px;
			figcaption{
				height: 120px;
			}
		}
	}
}