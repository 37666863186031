.box-process-document{
	padding-left: 4rem;

	.title-process-document{
		background: rgba(76,191,211,1);
		background: -moz-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(62,99,162,1) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(76,191,211,1)), color-stop(100%, rgba(62,99,162,1)));
		background: -webkit-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(62,99,162,1) 100%);
		background: -o-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(62,99,162,1) 100%);
		background: -ms-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(62,99,162,1) 100%);
		background: linear-gradient(135deg, rgba(76,191,211,1) 0%, rgba(62,99,162,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4cbfd3', endColorstr='#3e63a2', GradientType=1 );
		color: $color-initial;
		border-radius: .6rem;
		margin: 0 0 1.4rem 0rem;
		width: 100%;

		.column{
			display: flex;
			align-items: center;
			padding: 1rem;
			margin-bottom: 0;
			font-size: 1.5rem;
			font-weight: 600;

			&:first-child{
				padding-left: 3rem;
			}
			&:last-child{
				padding-right: 3rem;
			}
			&.column-center{
				justify-content: center;
			}
		}
	}

	.content-process-document{
		.list{
			border-radius: .6rem;
			padding: 2.4rem 0 2rem;
			box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,.16);
			position: relative;
			margin-bottom: 1rem;
			position: relative;

			&:before{
				content:"";
				background: #4CBED3;
				width: .1rem;
				@include position(absolute, 0 null -1rem -2.8rem);
			}
			&:after{
				content:"";
				border-left: .75rem solid transparent;
				border-right: .75rem solid transparent;
				border-top: .8rem solid #4CBED3;
				@include position(absolute, null null 3rem -3.5rem);
			}
			.number-process{
				width: 2.6rem;
				height: 2.6rem;
				line-height: 2.6rem;
				border-radius: 50%;
				background: #4CBED3;
				color: $color-initial;
				font-size: 1.3rem;
				font-weight: 700;
				display: block;
				text-align: center;
				@include position(absolute, 3.5rem null null -4rem);
			}
			h5{
				font-size: 1.6rem;
				font-weight: 700;
			}

			&:first-child{
				&:before{
					top: 4rem;
				}
			}

			&:last-child{
				&:before{
					bottom: auto;
					height: 4rem;
				}
				&:after{
					display: none;
				}
			}
		}
		.row{
			margin: 0;
			width: 100%;
			position: relative;

			&:before{
				content:"";
				border-bottom: .1rem solid rgba(112,112,112,.19);
				@include position(absolute, null 3rem 0);
			}

			.column{
				padding: 1rem 1rem 1.5rem;
				font-size: 1.5rem;
				word-wrap: break-word;

				h5,hr{
					margin-bottom: 0;
				}
				hr{
					margin-top: 1.5rem;
				}

				&:first-child{
					padding-left: 3rem;
				}
				&:last-child{
					padding-right: 3rem;
				}
			}

			&:last-child{
				&:before{
					display: none;
				}
				.column{
					padding-bottom: 0;
				}
			}
		}
	}
}

.box-rounded-form{
	border-radius: 1rem;
	box-shadow: 0 0 .6rem 0 rgba(0,0,0,.16);

	.title-rounded-form{
		border-radius: 1rem 1rem 0 0;
		background: #353587;
		color: $color-initial;
		padding: 3rem 5rem;

		span{
			display: block;
			color: rgba(255,255,255,.5);
			font-size: 1.2rem;
			font-weight: 600;
			text-transform: uppercase;
		}
		h4{
			color: $color-initial;
			margin-bottom: 0;
		}
	}
	.content-rounded-form{
		padding: 4rem 21rem;
		color: #818181;
		font-size: 1.5rem;

		&.light-space{
			padding: 3.4rem 3rem;
		}
		&.no-padding{
			padding: 0;
		}
	}
}

.box-data-list{
	label{
		margin-bottom: 0;
	}
	a{
		color: #818181;
		text-decoration: underline;
	}
	.row{
		margin-bottom: 2rem;
		line-height: 2.4rem;
	}
}

.delete-row{
	display: block;
	width: 3rem;
	height: 3rem;
	cursor: pointer;
	transition: .2s all ease-out;
	background: url('../images/material/icon-delete.png')no-repeat center;

	&:hover{
		transform: scale(.9);
	}
}