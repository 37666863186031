.overlay-select-document{
	background: rgba(0,0,0,0.5);
	z-index: 9;
	display: none;
	@include position(fixed, 0);
}
.search-document{
	padding: 2rem 3rem 2.6rem;
	background: #353587;
	color: $color-initial;
	border-radius: 1rem;
	margin-bottom: 4rem;
	position: relative;
	z-index: 10;

	label{
		font-size: 1.3rem;
		font-weight: 700;
	}
	input, select{
		background: rgba(255,255,255,.9);
		height: 5rem;
		padding: 0 1.7rem;
		color: #111111;
		font-size: 1.5rem;
		border-radius: 1rem;
		margin: 0;
	}
	select{
		padding-right: 4rem;
		background-image: url('../images/material/arrow-select-blue.svg');
		background-repeat: no-repeat;
		background-position: right center;
	}
	button{
		margin: 0;
		padding: 0 1.5rem;
	}
	&.active{
		z-index: 10000;
	}
	.accordion-select{
		.list-acc{
			margin-top: 10px;
			.button{
				width: 100%;
			}
		}
		.list-acc{
			.acc-select{
				position: inherit;
			}
		}
	}
}

.side-by-side-sort{
	display: flex;
	justify-content: space-between;
	margin-bottom: 3rem;
	.column{
		display: flex;
		align-items: center;
	}
	.box-dropdown-select{
		font-size: 1.5rem;
		line-height: 1.3;
		height: 5rem;
		width: auto;
		max-width: 27rem;
		min-width: 22rem;
		display: inline-block;
		border: .1rem solid #E0E0E0;
		border-radius: 1rem;
		margin: 0;
		background: #fff url(../images/material/arrow-select-blue.svg) no-repeat center right;
		.text-select{
			display: block;
			height: 5rem;
			line-height: 5rem;
			padding: 0 4rem 0 1.7rem;
			color: #111111;
			font-size: 1.5rem;
			border-radius: 1rem;
			margin: 0;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.dropdown-select{
			margin-top: 0;
			box-shadow: 0;
			border: .1rem solid #E0E0E0;
			&:before{
				display: none;
			}
			.list-select{
				&:hover, &.selected{
					background: unset;
					color: #333333;;
				}
			}
		}
		.list{
			display: flex;
			align-items: flex-start;
			border-bottom: 1px solid #E2E2E2;
			padding-bottom: 1rem;
			figure{
				width: 3rem;
				margin-right: 1.5rem;
				img{
					width: 3rem;
				}
			}
			figcaption{
				width: calc( 100% - 5rem );
				span{
					display: block;
					font-size: 1.3rem;
					color: #A5A5A5;
				}
			}
		}
	}
}

.rounded-doc{
	box-shadow: 0 0 .6rem 0 rgba(0,0,0,0.16);
	border-radius: .6rem;
	margin-bottom: 3rem;

	.title-rounded-doc{
		background: #F4F4F4;
		border-radius: .6rem .6rem 0 0;
		padding: 1.5rem 3.6rem;
		display: flex;
		justify-content: space-between;
		font-size: 1.2rem;
		color: #818181;

		.from-to{
			display: inline-block;
			margin-right: 1.6rem;
		}
	}

	.content-rounded-doc{
		padding: 3rem 3.6rem;

		h5{
			font-size: 1.5rem;
			font-weight: 700;
			margin-bottom: .5rem;
			color: $color-secondary;

			span{
				color: $color-primary;
			}
		}
		p{
			font-size: 1.5rem;
			line-height: 2.2rem;
			color: #818181;
		}
	}
	.detail-doc-popup{
		display: none;
	}
}

.data-doc{
	display: flex;
	justify-content: space-between;
	align-items: center;

	.text-data-doc{
		flex: 1 0 11rem;
		max-width: 11rem;
		font-size: 1.2rem;
		color: #818181;
	}
	.box-button-data-doc{
		text-align: right;

		.button{
			margin-bottom: 0;
			margin-right: .5rem;

			&:last-child{
				margin-right:0;
			}
		}
	}
	&.penelaahan-hukum{
		.box-button-data-doc{
			.button{
				width: auto;
				min-width: 11rem;
			}
		}
	}
}


.box-dropdown-select{
	position: relative;
	.text-dropdown-select{
		display: block;
		background: rgba(255,255,255,.9) url('../images/material/arrow-select-blue.svg') no-repeat center right;
		height: 5rem;
		line-height: 5rem;
		padding: 0 4rem 0 1.7rem;
		color: #111111;
		font-size: 1.5rem;
		border-radius: 1rem;
		margin: 0;
		cursor: pointer;
		overflow: hidden;
	}
	.dropdown-select{
		display: none;
		z-index: 1;
		background: $color-initial;
		box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,0.16);
		border-radius: .6rem;
		width: auto;
		max-width: 51rem;
		min-width: 100%;
		margin-top: 2.6rem;
		color: $color-secondary;
		@include position(absolute, 100% 0 null 0);
		transform: translateX(0);

		&:before{
			content:"";
			border-left: .9rem solid transparent;
			border-right: .9rem solid transparent;
			border-bottom: 1.7rem solid $color-initial;
			margin-left: -.5rem;
			@include position(absolute, null null 100% 50%);
		}
		.list-select{
			padding: 1rem;
			font-size: 1.4rem;
			line-height: 1.4;
			cursor: pointer;
			white-space: unset;

			&:hover, &.selected{
				background: rgba(54,54,137,.2);
				color: #353587;
			}
		}
		.overflow-dropdown-select{
			max-height: 45rem;
			width: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 1.6rem 0;
			/* width */
			&::-webkit-scrollbar {
				width: 6px;
			}
			
			/* Track */
			&::-webkit-scrollbar-track {
				border-radius:6px;
			}
			
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: rgba(0,0,0,0.4);
				border-radius: 6px;
			}
			
			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: rgba(0,0,0,0.7);
			}
		}
	}
}

.select-custom{
	position: relative;
	border: .1rem solid #E0E0E0;
	height: 5rem;
	line-height: 4.8rem;
	font-size: 1.5rem;
	border-radius: 1rem;
	background: $color-initial url('../images/material/arrow-select-blue.svg')no-repeat center right;
	display: inline-block;
	width: 22rem;
	vertical-align: middle;
	.button-select-custom{
		cursor: pointer;
		height: 5rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 0 3.4rem 0 1.7rem;
	}
	.drop-select-custom{
		border: .1rem solid #E0E0E0;
		background: $color-initial;
		border-radius: 1rem;
		z-index: 1;
		padding: 1.5rem 0 0;
		display: none;
		@include position(absolute, 100% 0 null);
		h5{
			font-weight: 700;
			padding: 0 2rem;
			margin-bottom: 1rem;
		}
		img{
			width: 3rem;
			margin-right: 1rem;
			flex-shrink: 1;
		}
		ul{
			margin-bottom: 0;
			border-radius:0 0 1rem 1rem;
			li{
				padding: 1rem;
				border-bottom: .1rem solid #eeeeee;
				display: flex;
				align-items: center;
				justify-content: space-between;
				line-height: 1.3;
				font-size: 1.3rem;
				word-wrap: break-word;
				margin: 0;
				&:before{display: none;}
				a{
					display: flex;
					align-items: center;
					color: $color-secondary;
				}
				span{
					word-break: break-word;
				}
				&:last-child{
					border-radius:0 0 1rem 1rem;
					border-bottom: none;
				}
				&:hover{
					background: $color-primary;
					a{
						color: $color-initial;
					}
				}
			}
		}
	}
}

.detail-document, .detail-hukum{
	.title-detail-document{
		background: #F5F5F5;
		padding: 4rem;
		border-radius: .6rem .6rem 0 0;

		h5{
			font-size: 1.5rem;
			font-weight: 700;
			margin-top: 1rem;
			margin-bottom: .5rem;
			span{
				color: #353587;
			}
		}
		p{
			font-size: 1.5rem;
			color: #818181;
			margin-bottom: 0;
		}
	}
	.desc-detail-document{
		padding: 4rem 3.6rem;
		.slider-grafis{
			position: relative;
			padding-bottom: 4rem;
			margin-bottom: 2rem;
			.slick-slide img {display: block; margin: auto;}
			.slick-arrow{
				background: url('../images/material/arrow-left-blue.svg') no-repeat left;
				border-radius: 0;
				padding: 0;
				margin: -3rem 0 0;
				font-size: 14px;
				color: #6262A5;
				border: none;
				z-index: 2;
				transition: .2s all ease-out;
				padding-left: 20px;
				@include position(absolute, 0 null 0 null);
				margin: auto;
		
				&.slick-next{
					left: auto;
					right: 0;
					padding: 0;
					padding-right: 20px;
					background: url('../images/material/arrow-right-blue.svg') no-repeat right;
				}
		
				&:hover{
					&.slick-prev{
						padding-left: 2.5rem;
					}
					&.slick-next{
						padding-right: 2.5rem;
					}
				}
			}
			.slick-dots{
				list-style: none;
				display: flex;
				margin: 0;
				padding: 0;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				li{
					margin: 0;
					padding: 0;
					margin: 0 5px;
					button{
						background: #fff;
						width: 14px;
						height: 14px;
						border-radius: 100%;
						border: 2px solid #A5A5A5;
						padding: 0;
						font-size: 0;
						margin: 0;
		
					}
					&::before{
						display: none;
					}
					&.slick-active{
						button{
							background: #4CBED3;
							border-color: #4CBED3;
						}
					}
				}
			}
		}

	}
	.title-desc-detail-document{
		position: relative;
		display: flex;
		align-items: center;
	
		> img{
			flex: 1 0 4.8rem;
			max-width: 4.8rem;
			margin-right: 2rem;
		}
		h4{
			margin-bottom: 0;
		}
	}
}

.table-document{
	width: 100%;
	td, th{
		font-size: 1.6rem;
		line-height: 2.8rem;
		color: #111111;
		padding: .5rem 1rem;

		a{
			color: #353587;
			text-decoration: underline;
			font-weight: 700;
		}

		&:first-child{
			width: 30rem;
			color: #888888;
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
		}
	}
}

.detail-hukum{
	box-shadow: 0 0 .6rem 0 rgba(0,0,0,0.16);
	border-radius: .6rem;
	padding: 4.5rem 3rem;
	box-sizing: border-box;
	h4{
		margin-bottom: 2rem;
	}
	.label-outline{
		margin-bottom: 1.5rem;
	}
	.table-document{
		margin-top: 3rem;
	}
	.data-doc{
		.text-data-doc{
			font-size: 16px;
			flex: 1 0 15rem;
			max-width: 15rem;
		}
	}
}
