@media all and (max-width: 1300px){
    .box-dropdown-select{
        .dropdown-select{
            .overflow-dropdown-select{
                max-height: 30rem;
            }
        }
    }
    .rounded-doc{
        .title-rounded-doc{
            padding-left: 2.1rem;
            padding-right: 2.1rem;
        }
        .content-rounded-doc{
            padding: 3rem 2.1rem 2rem;
        }
    }
    .detail-document,
    .detail-hukum {
        .desc-detail-document {
            .slider-grafis {
                .slick-arrow {
                    font-size: 0; background-position: center !important; width: 36px; height: 36px; padding: 0;
                    border-radius: 50%; background-color: rgba(255,255,255,.6) !important; box-shadow: 0px 10px 30px rgba(0,29,59,0.1);
                }
            }
        }
    }
}

@media all and (max-width: 1020px){
    .search-document{
        flex-wrap: wrap;
        .column{
            &.column-35{
                flex-basis: 100%;
                max-width: 100%;
                margin-bottom: 2.3rem;
            }
            &.column-20, &.column-15{
                flex: 2;
                max-width: 100%;
            }
            &.column-10, &.column-13{
                flex: 1;
                max-width: 100%;
            }
            &.column-60{
                flex: 6;
                max-width: 100%;
            }
            &.column-87, &.column-90{
                flex: 9;
                max-width: 100%;
            }

            &:nth-of-type(2){
                .box-dropdown-select{
                    .dropdown-select{
                        left: 0;
                        transform: translateX(0);
                        &:before{
                            left: 8.5rem;
                        }
                    }
                }
            }
        }
    }
    .data-doc{
        flex-wrap: wrap;
        .text-data-doc, .box-button-data-doc{
            flex-basis: 100%;
            max-width: 100%;
            text-align: right;
        }
        .text-data-doc{
            margin-bottom: 1rem;
        }
    }
    .side-by-side-sort{
        .box-dropdown-select{
            max-width: 20rem;
        }
    } 
}