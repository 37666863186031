.box-contact{
	border-radius: .8rem;
	box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,0.16);

	.img-contact{
		position: relative;
		color: $color-initial;
		figure{
			z-index: 1;
			@include position(absolute, 0);
			&:before{
				content:"";
				z-index: 1;
				border-radius: .8rem 0 0 .8rem;
				@include position(absolute, 0);
				background: rgba(76,191,211,0.2);
				background: -moz-linear-gradient(-45deg, rgba(76,191,211,0.2) 0%, rgba(54,54,137,1) 75%);
				background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(76,191,211,0.2)), color-stop(75%, rgba(54,54,137,1)));
				background: -webkit-linear-gradient(-45deg, rgba(76,191,211,0.2) 0%, rgba(54,54,137,1) 75%);
				background: -o-linear-gradient(-45deg, rgba(76,191,211,0.2) 0%, rgba(54,54,137,1) 75%);
				background: -ms-linear-gradient(-45deg, rgba(76,191,211,0.2) 0%, rgba(54,54,137,1) 75%);
				background: linear-gradient(135deg, rgba(76,191,211,0.2) 0%, rgba(54,54,137,1) 75%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4cbfd3', endColorstr='#363689', GradientType=1 );
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: .8rem 0 0 .8rem;
			}
		}
		figcaption{
			position: relative;
			z-index: 2;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 5.2rem 3.3rem;
			height: 100%;
			font-size: 1.5rem;
			line-height: 2.6rem;

			h5{
				font-weight: 700;
				font-size: 1.8rem;
				line-height: 2.8rem;
			}
			a{
				color: $color-initial;
			}
		}
	}
	.right-box-contact{
		padding: 4.4rem 5rem 4.4rem 4rem !important;
	}
}

.box-lacak-dokumen{
	box-shadow: 0 0 .6rem 0 rgba(0,0,0,0.16);
	border-radius: .6rem;
	font-size: 1.5rem;
	.title-lacak-dokumen{
		display: flex;
		justify-content: space-between;
		padding: 3rem 3.5rem;

		> div{
			&:last-child{
				flex: 1 0 68.5rem;
				max-width: 68.5rem;
			}
		}
		h5{
			font-size: 2rem;
			font-weight: 700;
			color: #111111;
		}
		span{
			display: block;
			font-size: 1.2rem;
			color: #818181;
			margin-bottom: .5rem;
		}
	}
	.btn-lacak-dokumen{
		background: #F5F5F5;
		padding: 2rem 3.5rem;
		border-radius: 0 0 .6rem .6rem;
		display: flex;
		justify-content: space-between;

		.span-label{
			font-size: 1.2rem;
			color: #818181;
			display: inline-block;
			margin-right: 3.6rem;
		}
	}
	.toggle-lacak-dokumen{
		font-weight: 700;
		color: #353587;
		font-size: 1.4rem;
		padding-right: 4.2rem;
		position: relative;

		&:before{
			content:"";
			width: 4.1rem;
			background: url('../images/material/arrow-select-blue.svg')no-repeat center right;
			@include position(absolute, 0 0 0 null);
		}
	}
	.content-lacak-dokumen{
		display: none;
		padding: 4.3rem 3.6rem 4rem;
	}

	&.show{
		.toggle-lacak-dokumen{
			&:before{
				transform: rotate(180deg);
			}
		}
		.content-lacak-dokumen{
			display: block;
		}
	}
}

.row-process-document{
	width: 32.2rem;
	margin: 0 auto;

	.list-pro-doc{
		position: relative;
		padding-left: 6.7rem;
		margin-bottom: 3rem;

		&:before{
			content:"";
			width: .1rem;
			background: #00B300;
			@include position(absolute, .5em null -3rem 1.6rem);
		}
		> span{
			display: block;
			background: #00B300 url('../images/material/icon-checklist.svg')no-repeat center;
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
			@include position(absolute, .6rem null null .6rem);

			&:before{
				content:"";
				border-radius: 50%;
				background: rgba(0,179,0,.2);
				@include position(absolute, -.6rem);
			}
		}
		h6{
			font-size: 1.4rem;
			font-weight: 700;
			margin-bottom: 0;
		}
		p{
			margin-bottom: 0;
		}

		&.blue{
			> span{
				background: rgba(73,171,201,1);
				background: -moz-linear-gradient(-45deg, rgba(73,171,201,1) 0%, rgba(59,85,153,1) 100%);
				background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(73,171,201,1)), color-stop(100%, rgba(59,85,153,1)));
				background: -webkit-linear-gradient(-45deg, rgba(73,171,201,1) 0%, rgba(59,85,153,1) 100%);
				background: -o-linear-gradient(-45deg, rgba(73,171,201,1) 0%, rgba(59,85,153,1) 100%);
				background: -ms-linear-gradient(-45deg, rgba(73,171,201,1) 0%, rgba(59,85,153,1) 100%);
				background: linear-gradient(135deg, rgba(73,171,201,1) 0%, rgba(59,85,153,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49abc9', endColorstr='#3b5599', GradientType=1 );
				&:before{
					background: rgba(124,181,236,.2);
					animation: scaleeffect .7s infinite;
				}
			}
		}

		&:last-child{
			&:before{
				bottom: auto;
				height: 1rem;
			}
		}
	}
}

@keyframes scaleeffect {
	from {transform: scale(.7);}
	to {transform: scale(1);}
}

.text-not-found{
	padding: 4rem ;
	border-radius: .5rem;
	box-shadow: 0 0 0.6rem 0 rgba(0,0,0,0.06);
	margin: 0 auto;
	width: 100%;
	max-width: 70rem;
	p{
		margin-bottom: 0;
	}
}

.box-search-lg{
	margin-bottom: 4.6rem;
	position: relative;

	input[type=text]{
		border: none;
		box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,0.16);
		border-radius: 2rem;
		height: 9rem;
		padding: 0 9rem 0 4rem;
		font-family: $font-tertiary;
		font-size: 2.3rem;
		color: #555555;
	}
	input[type=submit]{
		width: 3.2rem;
		height: 3.2rem;
		background: url('../images/material/icon-search-large.svg')no-repeat center;
		@include position(absolute, 3rem 3.5rem 0 null);
	}
}

.text-grey{
	color: #999999;
}

.box-search-result{
	margin-bottom: 4.2rem;
	h5{
		font-size: 1.8rem;
		color: #111111;
		font-weight: 400;
		letter-spacing: 0;
		margin-bottom: 1.5rem;
	}
	p{
		color: #999999;
		margin-bottom: 0;
	}
	b{
		font-weight: 700;
		color: #353587;
	}
	.list{
		display: block;
		border-bottom: .1rem solid #E2E2E2;
		padding:2.8rem 0 3.8rem;
		position: relative;

		&:before{
			content:"";
			width: 0;
			height: .1rem;
			background: $color-primary;
			transition: .3s all ease-out;
			@include position(absolute, null null 0 0);
		}
		&:hover{
			&:before{
				width: 100%;
			}
		}
		&:first-child{
			border-top: .1rem solid #E2E2E2;
		}
	}
}

.notfound{
	padding: 9rem 0;
	.wrapper-small{
		max-width: 86rem;
	}
}

.box-notfound{
	display: flex;
	justify-content: space-between;
	align-items: center;

	h2{
		font-size: 4.2rem;
		font-weight: 700;
		color: #111111;
	}
	p{
		font-size: 2rem;
		color: #999999;
		line-height: 3rem;
	}
	figure{
		flex: 1 0 32.5rem;
		max-width: 32.5rem;
		margin-right: 2rem;
		img{
			width: 100%;
		}
	}
	.button{
		font-size: 1.6rem;
	}
}

.box-maintenance{
	padding: 8rem 0 0 18rem;
	position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
	color: $color-initial;
	font-size: 2rem;
	line-height: 2.4rem;

	h2{
		font-weight: 700;
		font-size: 4.2rem;
		line-height: 5rem;
		text-transform: uppercase;
		margin-bottom: 4rem;
	}
	hr{
		margin-bottom: 4rem;
	}
	h5{
		font-size: 2rem;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 2.4rem;
		margin-bottom: 1rem;
	}
	p{
		margin-bottom: 1rem;
		line-height: 2.4rem;
	}
	a{
		color: $color-initial;
	}
	figure{
		z-index: 1;
		@include position(absolute, 0);

		&:before{
			content:"";
			@include position(absolute, 0);
			background: rgba(53,53,135,1);
			background: -moz-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 30%, rgba(53,53,135,0.3) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(53,53,135,1)), color-stop(30%, rgba(53,53,135,1)), color-stop(100%, rgba(53,53,135,0.3)));
			background: -webkit-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 30%, rgba(53,53,135,0.3) 100%);
			background: -o-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 30%, rgba(53,53,135,0.3) 100%);
			background: -ms-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 30%, rgba(53,53,135,0.3) 100%);
			background: linear-gradient(135deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 30%, rgba(53,53,135,0.3) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#353587', endColorstr='#353587', GradientType=1 );
		}
		&:after{
			content:"";
			width: 100%;
			height: 140%;
			@include position(absolute, -80% null null -70%);
			background: rgba(76,190,211,1);
			background: -moz-radial-gradient(center, ellipse cover, rgba(76,190,211,1) 0%, rgba(76,190,211,0) 60%, rgba(76,190,211,0) 100%);
			background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,190,211,1)), color-stop(60%, rgba(76,190,211,0)), color-stop(100%, rgba(76,190,211,0)));
			background: -webkit-radial-gradient(center, ellipse cover, rgba(76,190,211,1) 0%, rgba(76,190,211,0) 60%, rgba(76,190,211,0) 100%);
			background: -o-radial-gradient(center, ellipse cover, rgba(76,190,211,1) 0%, rgba(76,190,211,0) 60%, rgba(76,190,211,0) 100%);
			background: -ms-radial-gradient(center, ellipse cover, rgba(76,190,211,1) 0%, rgba(76,190,211,0) 60%, rgba(76,190,211,0) 100%);
			background: radial-gradient(ellipse at center, rgba(76,190,211,1) 0%, rgba(76,190,211,0) 60%, rgba(76,190,211,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4cbed3', endColorstr='#4cbed3', GradientType=1 );
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	figcaption{
		width: 41.2rem;
		position: relative;
		z-index: 2;
	}
}