/* header
----------------------------------------------------------------------------------------------*/
header{
	z-index: 1000;
	@include position(fixed, 0 0 null);
	.head-top{
		background: $color-initial;
		padding: 2rem 0;
		transition: .2s all ease-out;
		.wrapper{
			display: flex;
			justify-content: space-between;
		}
		.logo{
			a{
				display: inline-block;
				margin-right: 3.2rem;
				vertical-align: middle;

				&:last-child{
					margin-right: 0;
				}
			}
			img{
				height: 49px;
				transition: .2s all ease-out;
				vertical-align: middle;
			}
		}
	}

	&.float{
		.head-top{
			padding: 1rem 0;
			.logo{
				img{
					height: 40px;
				}
			}
		}
		.button-login{
			background-size: auto 23px;
		}
	}


	.burger-nav{
		display: none;
	}
	.head-right{
		display: flex;
		align-items: center;
	}
	.space-text-vertical{
		border-left: 0.1rem solid rgba(51, 51, 51, 0.13);
		height: 2.2rem;
		display: inline-block;
		margin: 0 1.5rem;
	}
	.box-lang{
		position: relative;
		border-left: .1rem solid rgba(51,51,51,.13);
		height: 2.2rem;
		line-height: 2.2rem;
		padding-left: 1.5rem;
		margin: 0 1.4rem 0 1.7rem;

		a{
			color: #6C6CC9;
			font-size: 1.4rem;

			&:hover, &.active{
				color: $color-primary;
			}
			&.active{
				font-weight: 700;
			}
		}
	}
	.search-button{
		width: 2.4rem;
		height: 2.4rem;
		background: url('../images/material/icon-search.svg') no-repeat center;
	}
	.box-search-header{
		position: relative;
		width: 22rem;
		input[type='text']{
			height: 4rem;
			background: #F7F7F7;
			border-radius: .6rem;
			border-color: transparent;
			padding: 0 4rem 0 1.5rem;
			margin: 0;
			font-size: 1.4rem;

			&:focus, &:active{
				border-color:#363689;
			}
		}
		input[type='submit']{
			font-size: 0;
			color: transparent;
			padding: 0;
			margin: 0;
			width: 2.4rem;
			border: none;
			background: url('../images/material/icon-search.svg') no-repeat center;
			@include position(absolute, .4rem 1rem 0 null);
		}
	}

	.head-bottom{
		background: rgba(76,191,211,1);
		background: -moz-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(53,53,135,1) 29%, rgba(53,53,135,1) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(76,191,211,1)), color-stop(29%, rgba(53,53,135,1)), color-stop(100%, rgba(53,53,135,1)));
		background: -webkit-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(53,53,135,1) 29%, rgba(53,53,135,1) 100%);
		background: -o-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(53,53,135,1) 29%, rgba(53,53,135,1) 100%);
		background: -ms-linear-gradient(-45deg, rgba(76,191,211,1) 0%, rgba(53,53,135,1) 29%, rgba(53,53,135,1) 100%);
		background: linear-gradient(135deg, rgba(76,191,211,1) 0%, rgba(53,53,135,1) 29%, rgba(53,53,135,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4cbfd3', endColorstr='#353587', GradientType=1 );
		padding-top: 1.8rem;
	}
	.nav{
		margin: 0;
		
		> li{
			display: inline-block;
			margin: 0 2.5rem 0 0;
			padding-bottom: 1.8rem ;

			a{
				color: #C1C1E8;
				font-size: 1.5rem;
			}

			&:last-child{margin-right: 0;}
			&.active{
				> a{
					color:$color-initial;
					font-weight: 700;
				}
			}
			&.beranda{
				> a{
					padding-left: 3rem;
					background: url('../images/material/icon-home.svg')no-repeat center left;
				}
			}
			&.parent{
				position: relative;
				> a{
					position: relative;
					padding-right: 2rem;
					background: url('../images/material/arrow-select.svg')no-repeat right 60%;
				}
				> ul{
					background: $color-initial;
					width: 20rem;
					padding: 1rem 0;
					opacity: 0;
					visibility: hidden;
					margin-left: -10rem;
					border-radius: 0 0 .6rem .6rem;
					box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,0.16);
					transition: cubic-bezier(0.39, 0.575, 0.565, 1) all .3s;
					@include position(absolute, 100% null null 50%);

					&:before{
						content:"";
						border-left: 1rem solid transparent;
						border-right: 1rem solid transparent;
						border-bottom: 1.7rem solid $color-initial;
						margin: 0 0 -.5rem -1rem;
						@include position(absolute, null null 100% 50%);
					}
					a{color: $color-secondary;}
					> li{
						padding: 1rem 2rem;
						margin-bottom: 0;
						transition: cubic-bezier(0.39, 0.575, 0.565, 1) all .2s;

						&:hover,&.active{
							background: rgba(54,54,137,.2);
							a{
								color: #353587;
							}
						}
					}
				}

				&:hover{
					> ul{
						opacity: 1;
						visibility: visible;
					}
				}
			}
			&:hover{
				> a{
					color: $color-initial;
				}
			}
		}
	}
}

/* content
----------------------------------------------------------------------------------------------*/
.content-page{
	overflow: hidden;
}
.space-header{
	margin-top: 15rem;
}

.label-outline{
	display: inline-block;
	font-size: 1rem;
	font-weight: 700;
	color: #4CBED3;
	border: .1rem solid #4CBED3;
	padding: .2rem 1rem;
	border-radius: .5rem;
	text-transform: uppercase;
	transition: ease-out .2s all;

	&:hover{
		color: $color-initial;
		background: #4CBED3;
	}

	&.green{
		background: #00B300;
		border-color: #00B300;
		color: $color-initial;
	}
	&.red{
		background: #C33500;
		border-color: #C33500;
		color: $color-initial;
	}
}

.label-outline-middle{
	border: .1rem solid #4CBED3;
	background: #4CBED3;
	color: $color-initial;
	height: 3rem;
	line-height: 2.8rem;
	padding: 0 3rem;
	text-align: center;
	border-radius: 1.5rem;
	font-size: 1.5rem;
	font-weight: 600;
	display: inline-block;
	margin-bottom: 1.5rem;

	&:hover{
		color: #4CBED3;
		background: $color-initial;
	}
}

.side-title{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 3rem;
}

.box-sosmed{
	a{
		display: inline-block;
		margin: 0 .3rem;
		transition: .2s all ease-out;

		&:hover{
			transform: scale(.9);
		}
	}
}

.banner{
	position: relative;
	figure{
		img{
			width: 100%;
		}
	}
	.text-banner{
		width: 103rem;
		z-index: 1;
		color: $color-initial;
		text-align: center;
		margin: 0 auto;
		transform: translateY(-50%);
		@include position(absolute, 50% 0 null);
	}
}

.breadcrumbs{
	background: rgba(32,32,32,.1);
	padding: 1.6rem 0;
	ul{
		margin-bottom: 0;
		li{
			display: inline-block;
			margin: 0 1.4rem 0 0;
			padding-left: 3rem;
			position: relative;

			&:before{
				content:"";
				width: 1.2rem;
				background: url('../images/material/arrow-right.svg') no-repeat center;
				@include position(absolute, 0 null 0 0);
			}
			a{
				color: #818181;
				font-size: 1.5rem;
			}

			&:first-child{
				padding-left: 0;
				&:before{
					display:none;
				}
			}
			&.active{
				a{
					color: #111111;
					font-weight: 700;
				}
			}
		}
	}
}

.section-content{
	padding: 7.1rem 0 10rem;
}
.bg-grey{
	background: #F5F5F5;
}

.images-content{
	margin: 6rem -10.5rem;
	max-width: 103rem;
	img{
		width: 100%;
	}
}

.text-center{
	text-align: center;
}
.text-right{
	text-align: right;
}

.title-right{
	display: flex;
	justify-content: flex-end;
	margin-bottom: 3rem;
	
}

.sort-box{
	label{
		font-size: 1.3rem;
		font-weight: 700;
		display: inline-block;
		margin:0 3rem 0 0;
	}
	select{
		font-size: 1.5rem;
		line-height: 1.3;
		height: 5rem;
		width: auto;
		max-width: 27rem;
		display: inline-block;
		border: .1rem solid #E0E0E0;
		border-radius: 1rem;
		padding: 0 3.4rem 0 1.7rem;
		margin: 0;
		background: $color-initial url('../images/material/arrow-select-blue.svg')no-repeat center right;

		option{
			padding: 0;
			line-height: 1.3;
		}
	}
}

.pagination{
	display: flex;
	align-items: center;
	font-size: 1.5rem;

	label{
		font-size: 1.3rem;
		font-weight: 700;
		margin-right: 1.1rem;
	}
	a{
		display: inline-block;
		width: 5rem;
		height: 5rem;
		line-height: 4.8rem;
		text-align: center;
		border-radius: 1rem;
		border: .1rem solid #E0E0E0;
		margin-right: .5rem;
		transition: .2s all ease-out;

		&:hover,&.active{
			background: #4CBED3;
			border-color: #4CBED3;
			color: $color-initial;
		}
		&.active{
			font-weight: 700;
		}
	}
	.dots{
		display: inline-block;
		margin: 0 1rem 0 .5rem;
	}

	&.center{
		justify-content: center;
	}
}

.box-video{
	position: relative;
	border-radius: .6rem;

	figure{
		overflow: hidden;
		border-radius: .6rem;
		/*@include position(absolute, 0);*/

		&:before{
			content:"";
			background: rgba(0,0,0,0.5);
			z-index: 1;
			border-radius: .6rem;
			@include position(absolute, 0);
		}
		img{
			width: 100%;
			border-radius: .6rem;
		}
	}
	.btn-play-video{
		font-size: 2.4rem;
		font-weight: 700;
		padding-top: 8rem;
		color: $color-initial;
		z-index: 2;
		text-align: center;
		min-width: 8rem;
		background: url('../images/material/icon-play-video.svg')no-repeat top center;
		transform: translateY(-50%);
		cursor: pointer;
		@include position(absolute, 50% 0 null);
	}
	.wrap-iframe{
		display: none;
	}
	iframe{
		width: 100%;
	}
}

.side-icon{
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	> img{margin-right: 1.5rem;}
	h4{margin-bottom: 0;}
}

.accordion-text{
	.list-acc-text{
		border-bottom: .1rem solid #E2E2E2;
		padding: 3rem 0;
		position: relative;

		h5{
			font-size: 1.8rem;
			color: #111111;
			font-weight: 400;
			margin-bottom: 0;
		}
		p{
			font-size: 1.6rem;
			line-height: 3rem;
			color: #818181;
			margin-bottom: 0;
		}
		.btn-acc-text{
			cursor: pointer;
			padding-right: 9.5rem;
			position: relative;
			
			&:before{
				content:"";
				width: 2rem;
				height: 2rem;
				background: url('../images/material/arrow-select-blue.svg') no-repeat center left;
				display: block;
				transition: .3s all ease-out;
				@include position(absolute, .5rem 0 null null);
			}
		}
		.content-acc-text{
			padding-top: 1rem;
			display: none;
			padding-right: 9.5rem;
		}
		&.show{
			.btn-acc-text{
				&:before{
					transform: rotate(180deg);
				}
			}
			.content-acc-text{
				display: block;
			}
		}
		&:first-child{
			border-top: .1rem solid #E2E2E2;
		}
	}
}

.accordion-select{
	text-align: left;
	.list-acc{
		position: relative;
		.btn-acc-text{
			cursor: pointer;
			position: relative;
			text-align: right;
			padding-right: 3rem;
			h5{
				font-size: 13px;
				margin: 0;
				line-height: 30px;
			}
			&:before{
				content:"";
				width: 2rem;
				height: 2rem;
				background: url('../images/material/ico-plus.svg') no-repeat center left;
				display: block;
				transition: .3s all ease-out;
				@include position(absolute, .5rem 0 null null);
			}
		}
		.acc-select{
			display: none;
			position: absolute;
			// background: rgba(18, 29, 54, 0.67);
			// border-radius: 1rem;
			.box-form-underline{
				.row{
					margin: 0;
				}
				label, select{
					color: #fff;
				}
				select{
					background: url(../images/material/ico-arr-white-down.svg) center right no-repeat;
					font-family: sans-serif;
					font-size: 15px;
					option{
						color: #111;
					}
				}
			}
		}
		&.show{
			.btn-acc-text{
				&:before {background:  url('../images/material/ico-min.svg') no-repeat center left;;}
			}
			.acc-select{
				display: block;
			}
		}
	}
}
.tx{
	font-weight: 700;
	&.tx-green{
		color: #00B300;
	}
	&.tx-blue{
		color: #7CB5EC;
	}
	&.tx-orange{
		color: #F7A35C;
	}
	&.tx-red{
		color: #C33500;
	}
	&.tx-purple{
		color: #8085E9;
	}
	&.tx-black{
		color: #434348;
	}
}
.center-button{
	text-align: center;
	.button{
		margin-left: 2rem;
		margin-right: 2rem;
	}
}
.box-share{
	display: flex;
	border: .1rem solid #EEEEEE;

	> span{
		display: block;
		height: 5rem;
		line-height: 5rem;
		color: #888888;
		font-size: 1.4rem;
		font-weight: 600;
		padding: 0 1.6rem;
		border-right: .1rem solid #EEEEEE;
	}
	> div{
		padding: 0 1.6rem;
		display: flex;
		align-items: center;
		a{
			display: flex;
			align-items: center;
			margin-right: 1rem;
			transition: .2s all ease-out;

			&:last-child{
				margin-right: 0;
			}
			&:hover{
				transform: scale(.9);
			}
		}
	}
}
.app-foot{
	img{
		transition: .2s all ease-out;
		&:hover{
			transform: scale(.95);
		}
	}
}
.zoom-img{
	position: relative;
	&:before, &:after{
		content:"";
		transition: .2s all ease-out;
		cursor: pointer;
	}
	&:before{
		opacity: 0;
		background: url('../images/material/icon-zoom-in.svg')no-repeat center;
		background-size: 4rem;
		z-index: 2;
		@include position(absolute, 0);
	}
	&:after{
		background: rgba(0,0,0,0.7);
		opacity: 0;
		@include position(absolute, 0);
	}
	&:hover{
		&:before, &:after{
			opacity: 1;
		}
	}
}

.ui-datepicker .ui-datepicker-title select{
	font-size: 1.3rem;
	height: 3rem;
	line-height: 3rem;
	padding-left: 1rem;
	margin-left: .2rem;
	margin-right: .2rem;
}

/* footer
----------------------------------------------------------------------------------------------*/
.back-to-top{
	width: 5rem;
	height: 5rem;
	background: $color-primary url('../images/material/arrow-select.svg')no-repeat center;
	border-radius: 50%;
	transform: rotate(180deg);
	cursor: pointer;
	z-index: 10;
	transition: .2s all ease-out;
	opacity: 0;
	visibility: hidden;
	@include position(fixed, null 2.2rem 9rem null);

	&:hover{
		background-color: rgba(82,177,203,1);
	}
	&.show{
		opacity: 1;
		visibility: visible;
	}
}
footer{
	background: #1A1818;
	color: #767676;

	a{
		color: #767676;
		&:hover{
			color: #A5A5A5;
			text-decoration: underline;
		}
	}
	h5,p,table{
		font-size: 1.5rem;
		line-height: 2.2rem;
	}
	h5{
		font-weight: 700;
		color: #A5A5A5;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}
	p{
		margin-bottom: 1rem;
	}
	.award{
		img{
			margin: 5px;
		}
	}
	.foot-top{
		padding: 6rem 0 4rem;
		border-bottom: .1rem solid rgba(112,112,112,.13);

		ul{
			li{
				font-size: 1.4rem;
			}
		}
	}
	.foot-bottom{
		padding: 3rem 0;

		p{
			font-size: 1.4rem;
			margin-bottom: 0;
		}
		.link-foot{
			a{
				margin-right: 2rem;
				font-weight: 600;
				font-size: 1.4rem;
			}
		}
	}
}