@media all and (max-width: 1300px){
    .box-maintenance{
        padding-top: 9rem;
        padding-left: 9rem;
        font-size: 1.6rem;
        min-height: 45rem;
        h2{
            font-size: 3rem;
            line-height: 1.2;
            margin-bottom: 2rem;
        }
        h5{
            font-size: 1.8rem;
        }
        hr{
            margin-bottom: 2rem;
        }
    }
    .notfound{
        padding: 7rem 0;
    }
    .box-notfound{
        h2{
            font-size: 4rem;
        }
        p{
            font-size: 1.8rem;
        }
        .button{
            font-size: 1.4rem;
        }
    }
}

@media all and (max-width: 1020px){
    .box-contact{
        .row{
            flex-wrap: wrap;
            .column{
                &.img-contact{
                    flex: 1 0 100%;
                    max-width: 100%;
                }
            }
        }
        .img-contact{
            order: 2;
            figure{
                border-radius: 0 0 .8rem .8rem;
                &:before, img{
                    border-radius: 0 0 .8rem .8rem;
                }
            }
        }
        .right-box-contact{
            order: 1;
            padding: 3rem 3.5rem !important;
        }
    }
    .contact-section{
        .wrapper-middle{
            max-width: 56rem;
        }
    }
    .box-lacak-dokumen{
        .tx{font-size: 1.4rem;}
        .title-lacak-dokumen{
            padding: 2rem;
            > div{
                padding-right: 1rem;
                &:first-child{
                    flex: 1;
                }
                &:nth-child(2){
                    flex: 2;
                }
                &:last-child{
                    flex: 7;
                    padding-right: 0;
                }
            }
        }
    }
    .notfound{
        display: flex;
        align-items: center;
        .wrapper-small{
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }
    .box-notfound{
        h2{font-size: 3rem;}
        p{line-height: 2.4rem;}
        figure{
            flex: 1 0 25rem;
            max-width: 25rem;
        }
    }
    .box-search-lg{
        margin-bottom: 2rem;
        input[type=text]{
            height: 6rem;
            font-size: 1.8rem;
            padding-left: 3rem;
            padding-right: 7rem;
        }
        input[type=submit]{
            top: 1.5rem;
            right: 1.5rem;
        }
    }
}