@media all and (max-width: 1300px){
    header{
        box-shadow: 0 0 1rem 0 rgba(0,0,0,0.16);
        .overlay-head{
            background: rgba(0,0,0,0.51);
            @include position(fixed, 9rem 0 0);
            opacity: 0;
            visibility: hidden;
            transition: .3s all ease-out;
            
            &.show{
                opacity: 1;
                visibility: visible;
            }
        }
        .header-registered{
            .space-text-vertical{
                display: none;
            }
            a{
                display: block;
                margin-bottom: .5rem;
            }
        }
        .box-lang{
            border-left: none;
            margin: 0;
            line-height: 3rem;
            height: auto;
        }
        .box-search-header{
            width: 100%;
            margin-top: 1.5rem;
            input[type='text']{
                background: $color-initial;
            }
        }
        .nav{
            > li{
                display: block;
                margin: 0;
                padding: 1rem 2rem;
                border-bottom: .1rem solid rgba(255,255,255,.12);

                > a{
                    display: block;
                }
                &.parent{
                    > ul{
                        position: relative;
                        top: auto;
                        left: auto;
                        margin: 0;
                        padding: 0;
                        opacity: 1;
                        visibility: visible;
                        width: 100%;
                        background: transparent;
                        box-shadow: none;
                        display: none;
                        transition: none;
                        padding-top: .5rem;
                        &:before{
                            display: none;
                        }
                        a{
                            color: #C1C1E8;
                        }
                        > li{
                            padding: .7rem 0 .7rem 2rem;
                            &.active{
                                a{color: #fff;}
                            }
                            &:hover{
                                a{color: #C1C1E8;}
                            }
                        }
                    }
                }
                &.active{
                    &.parent{
                        > ul{
                            display: block;
                        }
                    }
                    &:hover{
                        > a{
                            color: #fff;
                        }
                    }
                }
                &:hover{
                    > a{
                        color: #C1C1E8;
                    }
                }
            }
        }
        .head-top{
            height: 9rem;
            display: flex;
            align-items: center;
        }
        .head-right{
            width: 37.5rem;
            height: auto;
            background: #F7F7F7;
            z-index: 2;
            @include position(fixed, null 0 0 null);
            padding: 1.7rem 2rem 2rem;
            flex-wrap: wrap;
            justify-content: space-between;
            display: none;
            align-items: flex-start;
        }
        .burger-nav{
            display: block;
            width: 9rem;
            height: 9rem;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: .2s all ease-out;
            @include position(absolute, 0 0 null null);
            background: rgba(62,102,163,1);
            background: -moz-linear-gradient(top, rgba(62,102,163,1) 0%, rgba(55,63,141,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(62,102,163,1)), color-stop(100%, rgba(55,63,141,1)));
            background: -webkit-linear-gradient(top, rgba(62,102,163,1) 0%, rgba(55,63,141,1) 100%);
            background: -o-linear-gradient(top, rgba(62,102,163,1) 0%, rgba(55,63,141,1) 100%);
            background: -ms-linear-gradient(top, rgba(62,102,163,1) 0%, rgba(55,63,141,1) 100%);
            background: linear-gradient(to bottom, rgba(62,102,163,1) 0%, rgba(55,63,141,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e66a3', endColorstr='#373f8d', GradientType=0 );

            span{
                display: block;
                width: 2.4rem;
                height: .2rem;
                border-radius: .2rem;
                background: $color-initial;
                margin-bottom: .5rem;
                transition: .2s all ease-out;

                &:last-child{
                    margin-bottom:0;
                }
            }
            &.close{
                span{
                    transform: rotate(25deg);
                }
            }
        }
        .head-bottom{
            padding-bottom: 16rem;
            width: 37.5rem;
            height: calc(100vh - 9rem);
            height: calc((var(--vh, 1vh) * 100) - 9rem);
            overflow-y: auto;
            background: #052460;
            display: none;
            transition: .2s height ease-out;
            @include position(fixed, null 0 0 null);
            .wrapper{
                padding: 0;
            }
        }

        &.float{
            .head-top{
                height: 7rem;
            }
            .burger-nav{
                width: 7rem;
                height: 7rem;
            }
            .overlay-head{
                top: 7rem;
            }
            .head-bottom{
                height: calc(100vh - 7rem);
            }
        }
    }

    .space-header{
        margin-top: 9rem;
    }
    .wrapper, .wrapper-middle{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .wrapper-small{
        max-width: 76.8rem;
    }
    .banner{
        figure{
            img{
                height: 26rem;
                object-fit: cover;
            }
        }
    }
    .images-content, .box-blue-gradient{
        margin-left: -6rem;
        margin-right: -6rem;
    }
    .box-blue-gradient{
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .section-content{
        padding-top: 3rem;
    }
    table{
        &.table-head-blue{
            th, td{
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
    .link, .link-blue{
        &:focus, &:hover{
            &:before{
                width: 0;
            }
        }
    }
    .link{
        &:focus, &:hover{
            color: #18188D;
        }
    }
    .link-blue{
        &:focus, &:hover{
            color: #353587;
        }
    }
}

@media all and (max-width: 1020px){
    body{
        font-size: 1.5rem;
    }
    .banner{
        h3{
            font-size: 2.5rem;
        }
        .text-banner{
            width: 100%;
            padding: 0 3rem;
        }
    }
    .wrapper-small{
        padding-left: 10rem;
        padding-right: 10rem;
    }
    .images-content{
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .button, button, input[type='button'], input[type='reset'], input[type='submit']{
        &.button-side-icon{
            font-size: 1.1rem;
            width: 10.2rem;
            height: 3rem;
            line-height: 2.8rem;
            padding-left: .5rem;
            padding-right: 3.3rem;

            span{
                width: 3rem;
                line-height: 3.3rem;
                img{
                    width: 1.5rem;
                }
            }
        }
        &.button-blue-light{
            &.type3{
                min-width: 18rem;
            }
        }
    }
    table{
        &.table-head-blue{
            th, td{
                &:first-child{
                    padding-left: 1rem;
                }
                &:last-child{
                    padding-right: 1rem;
                }
            }
        }
    }
    .accordion-text{
        .list-acc-text{
            .btn-acc-text{
                &:before{
                    top: .5rem;
                }
            }
        }
    }
    .row{
        &.row-around{
            margin-left: -1rem;
            width: calc(100% + 2rem);
            > .column{
                padding: 0 1rem;
            }
        }
    }
    .box-form-underline{
        #catcha{
            transform: scale(.7);
            transform-origin: 0;
        }
    }

    .share-only{
        .column.column-30{
            flex-basis: 40%;
            max-width: 40%;
        }
    }

    footer{
        .foot-top{
            .row{
                flex-wrap: wrap;
                justify-content: flex-start;
                .column{
                    &.column-30{
                        flex-basis: 100%;
                        max-width: 100%;
                        margin-bottom: 4rem;
                    }
                    &.column-18{
                        flex-basis: 30%;
                        max-width: 30%;
                    }
                    &.column-15{
                        flex-basis: 25%;
                        max-width: 25%;
                        justify-self: flex-end;
                    }
                }
            }
        }
        .foot-bottom{
            .row{
                .column{
                    &.column-60{
                        flex-basis: 75%;
                        max-width: 75%;
                    }
                    &.column-40{
                        flex: 1;
                    }
                    .box-sosmed{
                        a{
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .accordion-select{
        .list-acc{
            .acc-select{
                .button{
                    padding: 0 1rem;
                }
            }
        }
    }
}