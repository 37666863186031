@media all and (max-width: 720px){
    .search-document{
        padding: 3rem 1rem 1rem;
        .column{
            margin-bottom: 2rem !important;
            flex-basis: 100% !important;
            max-width: 100% !important;
            &.column-35{margin-bottom: 2rem;}
        }
        .button{
            width: 100%;
        }
    }
    .box-dropdown-select{
        .dropdown-select{
            width: 100%;
            left: auto;
            margin-left: 0;
            margin-top: .2rem;
            transform: translateX(0);
            &:before{
                display: none;
            }
            .list-select{
                white-space: normal;
                padding: .5rem 2rem;
                font-size: 1.3rem;
            }
            .overflow-dropdown-select{
                max-height: 20rem;
            }
        }
    }
    .side-by-side-sort{
        flex-direction: column;
        .column{
            margin-bottom: 1rem; justify-content: space-between;
        }
        .select-custom, select{
            width: 27rem;
            margin-left: 1rem;
        }
        .box-dropdown-select{
            max-width: unset;
            width: 27rem;
            min-width: 19rem;
            .dropdown-select{
                .overflow-dropdown-select{
                    width: auto;
                }
            }
        }
    }
    .sort-box{
        label{
            margin-bottom: 1rem;
            width: 50%;
            margin-right: 0;
        }
    }
    .rounded-doc{
        .title-rounded-doc{
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            flex-direction: column;
            .from-to{
                margin-right: 1rem;
                margin-bottom: .4rem;
            }
            .left-title{
                margin-bottom: .7rem;
            }
        }
        .content-rounded-doc{
            padding: 2rem 1.5rem;
        }
    }
    .data-doc {
        .text-data-doc,.box-button-data-doc {
            text-align: left;
        }
    }
    .data-doc{
        .box-button-data-doc{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .button{
                margin-right: 0;
                margin-bottom: 1rem;
                width: 48%;
            }
        }
        &.penelaahan-hukum{
            .box-button-data-doc{
                .button{
                    width: 48%;
                    min-width: unset;
                }
            }
        }
    }
    .detail-document{
        .title-detail-document{
            border-radius: 0;
            padding: 2.5rem 2rem;
        }
        .desc-detail-document{
            padding: 2.7rem 2rem;
            hr{
                margin: 2rem 0 0;
            }
            .title-desc-detail-document{
                > img{margin-right: 1.5rem;}
            }
        }
    }
    .table-document{
        display: block;
        tbody{display: block;}
        td, th{
            display: block;
            padding: 0;
            font-size: 1.5rem;
            line-height: 2.4rem;
            &:first-child{
                width: 100%;
            }
        }
        tr{
            display: block;
            margin-bottom: 2rem;
        }
    }
    .grafik-side{
        flex-direction: row;
        flex-wrap: wrap;
        .column{
            flex-basis: 100% !important;
            max-width: 100% !important;
        }
    }
}