@media all and (max-width: 720px){
    .popup{
        .inner-popup{
            margin: 15% auto;
            padding: 2rem;
            .close-popup{
                left: auto;
                right: 0;
                top: auto;
                bottom: 100%;
                margin-left: 0;
                margin-bottom: 1rem;
            }
            &.inner-small{
                padding: 2rem;
            }
            &.log-popup, 
            &.survey-popup{
                width: 90%;
                padding: 2rem;
                
                p{font-size: 1.4rem;}
                .remember-login{
                    flex-direction: row;
                    align-items: center;
                    a{font-size: 1.3rem;}
                    .checkbox{
                        i{
                            width: 1.4rem;
                            height: 1.4rem;
                            background-size: 200%;
                        }
                        span{font-size: 1.3rem;}
                        input[type=checkbox]{margin-bottom: 0;}
                    }
                }
            }
            &.survey-popup{
                hr{
                    margin: 2rem -2rem;
                }
                .title{
                    img{
                        width: 145px;
                    }
                }
                .star-rating{
                    > label {
                        width: 32px;
                        height: 32px;
                        &::before {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
        &#popup-document-hukum{
            .inner-popup{
                width: 100%;
                margin: 0;
                border-radius: 0;
            }
        }
    }
}