@media all and (max-width: 1020px){
    .side-detail{
        .column{
            &.column-34{
                flex-basis: 40%;
                max-width: 40%;
            }
            &.column-60{
                flex-basis: 58%;
                max-width: 58%;
            }
        }
    }
    .box-thumb-structure{
        figcaption{
            padding-top: 1rem;
            h5{
                font-size: 1.6rem;
                line-height: 1.3;
            }
        }
    }
}