@media all and (max-width: 1300px){
    .grafik-side{
        .column.column-45{
            padding-left: 9rem;
        }
    }
}

@media all and (max-width: 1020px){
    .grafik-side{
        .column{
            &.column-45{
                padding-left: 0;
            }
        }
    }
    .box-rounded-form{
        .title-rounded-form,
        .content-rounded-form{
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
    .box-process-document{
        .title-process-document{
            .column{
                font-size: 1.4rem;
                padding: 1rem .5rem;
                &.column-25{
                    flex-basis: 22%;
                    max-width: 22%;
                }
                &.column-19{
                    flex-basis: 12%;
                    max-width: 12%;
                }
                &.column-15{
                    flex-basis: 16%;
                    max-width: 16%;
                }
                &.column-9{
                    flex-basis: 12%;
                    max-width: 12%;
                }
                &:first-child{
                    padding-left: 2rem;
                }
                &:last-child{
                    padding-right: 2rem;
                }
            }
        }
        .content-process-document{
            .list{
                padding: 1.4rem 0 2rem;
                .number-process{
                    top: 2.5rem;
                }
            }
            .row{
                &:before{
                    left: 2rem;
                    right: 2rem;
                }
                .column{
                    font-size: 1.4rem;
                    padding: 1rem .5rem 1.5rem;

                    .side-icon{
                        display: inline-block;
                        img{
                            margin-right: 0;
                        }
                        span{
                            display: none;
                        }
                    }
                    &.column-25{
                        flex-basis: 22%;
                        max-width: 22%;
                    }
                    &.column-19{
                        flex-basis: 12%;
                        max-width: 12%;
                    }
                    &.column-15{
                        flex-basis: 16%;
                        max-width: 16%;
                    }
                    &.column-9{
                        flex-basis: 12%;
                        max-width: 12%;
                    }
                    &:first-child{
                        padding-left: 2rem;
                    }
                    &:last-child{
                        padding-right: 2rem;
                    }
                }
            }
        }
    }
}