@media all and (max-width: 720px){
    .box-rounded-form{
        .title-rounded-form, .content-rounded-form{
            padding: 2rem 1.5rem;
        }
        .content-rounded-form{
            &.light-space{
                padding: 2rem;
            }
        }

        &.rounded-detail-pustaka{
            border: none;
            box-shadow: none;
            border-radius: 0;
            .content-rounded-form{
                padding: 0;
                &.light-space{
                    padding: 0;
                }
            }
        }
    }
    .box-data-list{
        .row{
            margin-bottom: 1.5rem;
        }
    }
    .box-process-document{
        padding-left: 0;
        .title-process-document{
            display: none;
        }
        .content-process-document{
            .list{
                margin-top: 3rem;
                margin-bottom: 9rem;
                &:before{
                    left: 50%;
                    top: -9rem;
                    bottom: 100%;
                }
                &:after{
                    left: 50%;
                    bottom: -3rem;
                    margin-left: -.7rem;
                }
                .number-process{
                    left: 50%;
                    margin-left: -1.3rem;
                    top: -4rem;
                }
                &:first-child{
                    &:before{
                        top: -2rem;
                    }
                }
                &:last-child{
                    margin-bottom: 0rem;
                    &:before{
                        bottom: 100%;
                        height: auto;
                    }
                }
            }
            .row{
                margin-bottom: 2rem;
                padding-bottom: 2rem;
                flex-wrap: wrap;
                flex-direction: row;
                .column{
                    flex-basis: 100% !important;
                    max-width: 100% !important;
                    padding: .5rem 2rem;
                    text-align: left;
                    display: flex;
                    &:before{
                        content: attr(data-title);
                        flex: 1 0 50%;
                        max-width: 50%;
                    }
                    .side-icon{
                        display: flex;
                        img{margin-right: 1rem;}
                        span{display: block;}
                    }
                    &:first-child{
                        &:before{display: none;}
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                    padding-bottom: 0;
                    .column{
                        padding-bottom: .5rem;
                    }
                }
            }
        }
    }
}