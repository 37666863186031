@media all and (max-width: 1300px){
    .thumb-rounded{
        .column{
            h5{font-size: 1.6rem;}
            figcaption{
                padding: 1.5rem 2rem;
            }
        }
    }
    .filter-kamus{
        span{
            line-height: 4rem;
        }
    }
}
@media all and (max-width: 1020px){
    .thumb-rounded{
        .column{
            margin-bottom: 3rem !important;
            h6{font-size: 1.4rem;}
            figcaption{
                padding: 1.6rem 2rem;
                .button{
                    left: 2rem;
                    right: 2rem;
                }
            }
        }
    }
    .thumb-rounded-gallery{
        .list{
            margin-bottom: 2rem !important;
            figcaption{
                left: 1.3rem;
                right: 1.3rem;
                bottom: 2rem;
                span{font-size: 1.1rem;}
                p{font-size: 1.3rem;line-height: 1.4;}
            }
            &.video-act, &.photo-act{
                figure{
                    &:before{
                        left: 1.3rem;
                        top:1.5rem;
                    }
                }
            }
        }
    }
    .filter-kamus{
        &.row{
            .column.column-7{
                flex: 0 0 9%;
                max-width: 9%;
            }
        }
    }
}