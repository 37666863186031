@media all and (max-width: 720px){
    .box-contact{
        .right-box-contact{
            padding: 2rem !important;
        }
        .img-contact{
            figcaption{
                padding: 28rem 1.5rem 1rem;
            }
        }
    }
    .box-lacak-dokumen{
        .title-lacak-dokumen{
            flex-wrap: wrap;
            > div{
                &:first-child{
                    flex: 1 0 6.6rem;
                    max-width: 6.6rem;
                }
                &:last-child{
                    flex: 1 0 100%;
                    max-width: 100%;
                }
            }
        }
        .btn-lacak-dokumen{
            padding: 1rem 2rem;
            align-items: center;
            .span-label{
                margin-right: 0;
                display: block;
            }
        }
        .toggle-lacak-dokumen{
            padding-right: 2.5rem;
            &:before{
                margin-right: -1rem;
            }
        }
        .content-lacak-dokumen{
            padding: 4rem 2rem;
        }
    }
    .row-process-document{
        width: 100%;
        .list-pro-doc{
            padding-left: 5rem;
        }
    }
    .text-not-found{
        padding: 4rem 2rem;
    }
    .box-notfound{
        flex-wrap: wrap;
        h2{
            font-size: 2.4rem;
            margin-bottom: 1rem;
        }
        p{
            font-size: 1.6rem;
        }
        figure{
            flex: 1 0 100%;
            max-width: 100%;
            margin: 0 0 1.5rem;
            img{
                width: 14rem;
            }
        }
    }
    .notfound{
        padding: 3rem 0;
        .wrapper-small{
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    .box-maintenance{
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.6rem;
        h2{
            font-size: 2.4rem;
        }
        h5{
            font-size: 1.6rem;
        }
    }
}