.popup{
	background: rgba(0,0,0,.6);
	z-index: 1000;
	overflow: auto;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	display: none;
	@include position(fixed, 0);

	.overlay-popup{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		@include position(absolute, 0);
	}
	.inner-popup{
		width: 106rem;
		background: $color-initial;
		border-radius: .6rem;
		margin: 5% auto;
		padding: 5rem 6rem;
		z-index: 2;
		@include position(absolute, null 0);

		.close-popup{
			width: 1.6rem;
			height: 1.6rem;
			margin-left: 1rem;
			cursor: pointer;
			@include position(absolute, 0 null null 100%);

			span{
				display: block;
				height: .3rem;
				background: $color-initial;
				transform: rotate(45deg);
				@include position(absolute, 50% 0 null);

				&:last-child{
					transform: rotate(-45deg);
				}
			}
		}

		.full-img{
			position: relative;
			img{
				width: 100%;
			}
		}

		&.no-padding{
			padding: 0;
		}

		&.inner-small{
			max-width: 90%;
			width: 86rem;
			padding: 4rem 3.6rem;
		}

		&.in-full-img{
			padding: 0;
			background: transparent;
		}

		&.log-popup{
			background: rgba(65,126,175,1);
			background: -moz-linear-gradient(-45deg, rgba(65,126,175,1) 0%, rgba(53,53,135,1) 80%, rgba(53,53,135,1) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(65,126,175,1)), color-stop(80%, rgba(53,53,135,1)), color-stop(100%, rgba(53,53,135,1)));
			background: -webkit-linear-gradient(-45deg, rgba(65,126,175,1) 0%, rgba(53,53,135,1) 80%, rgba(53,53,135,1) 100%);
			background: -o-linear-gradient(-45deg, rgba(65,126,175,1) 0%, rgba(53,53,135,1) 80%, rgba(53,53,135,1) 100%);
			background: -ms-linear-gradient(-45deg, rgba(65,126,175,1) 0%, rgba(53,53,135,1) 80%, rgba(53,53,135,1) 100%);
			background: linear-gradient(135deg, rgba(65,126,175,1) 0%, rgba(53,53,135,1) 80%, rgba(53,53,135,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#417eaf', endColorstr='#353587', GradientType=1 );

			width: 50rem;
			color: $color-initial;
			padding: 4rem 5rem;
			font-size: 1.5rem;

			p{
				color: rgba(255,255,255,.5);
				line-height: 1.6;
				a{
					color: $color-initial;
				}
			}
			a{
				color: #86A2C9;
				font-weight: 700;

				&:hover{
					color: $color-initial;
				}
			}
			.side-icon{
				color: $color-initial;
				h4{color: $color-initial;}
			}
			.checkbox{
				span{color: #86A2C9;}
				&:hover{
					span{color: $color-initial;}
				}
			}
		}
		&.survey-popup{
			width: 53rem;
			padding: 4.5rem 6.5rem;
			.title{
				text-align: center;
			}
			hr{
				margin: 2rem -6.5rem;
			}
			.box-form{
				input[type='email'],
				input[type='number'],
				input[type='password'],
				input[type='search'],
				input[type='tel'],
				input[type='text'],
				input[type='url']
				{
					background-color: #F5F5F5;
					padding-left: 90px;
				}
				textarea{
					background-color: #F5F5F5;
					resize: none;
					height: 111px;
					&::placeholder{
						color: #333333;
						opacity: 50%;
						font-style: italic;
					}
				}
				.button{
					border-radius: 30px;
				}
				.formsurvey{
					.row{
						.column{
							position: relative;
							label{
								position: absolute;
								top: 15px;
								left: 40px;
								color: #333333;
								opacity: 50%;
								width: auto;
							}
							&.center{
								text-align: center;
								a{
									min-width: 17rem;
								}
							}
						}
					}
				}
			}
			.star-rating {
				display: flex;
				align-items: center;
				width: 100%;
				flex-direction: row-reverse;
				justify-content: center;
				position: relative;
				input {
					display: none;
				}
				> label {
					width: 46px;
					height: 46px;
					transition: 0.2s ease;
					margin: 5px;
					cursor: pointer;
					&:hover {
						transition: 0.2s ease;
					}
					&:active::before {
						transform:scale(1.1);
					}
					&::before {
						content: '';
						position: absolute;
						top: 0px;
						width: 46px;
						height: 46px;
						background: url('../images/material/ico-stars-2.svg') no-repeat center;
						background-size: 100%;
						filter: grayscale(1);
					}
				}
				input:checked ~ label:before {
					filter: grayscale(0);
				}
			}
		}
	}
}

.side-detail{
	figure{
		img{
			border-radius: .6rem;
			// box-shadow: 0 1rem 3rem 0 rgba(0,0,0,0.16);
		}
	}
}