@media all and (max-width: 720px){
    .side-line{
        font-size: 1.6rem;
        padding-left: 2rem;
        line-height: 1.4;
    }
    .row-structure{
        .row{
            flex-direction: row;
            flex-wrap: wrap;
            .column{
                flex-basis: 100% !important;
                max-width: 100% !important;
            }
            &.head-structure{
                .box-thumb-structure{
                    &:after{
                        display: none;
                    }
                }
            }
            &.sub-head-structure{
                padding-left: 3.5rem;
                .column{
                    &:before{
                        left: -2rem;
                        right: 100%;
                        top: 10rem;
                    }
                    &:after{
                        content:"";
                        display: block;
                        width: .3rem;
                        background: #6C6CB9;
                        @include position(absolute, 0 null 0 -2rem);
                    }
                    &:first-child{
                        &:before{
                            left: -2rem;
                        }
                        &:after{
                            top: -2.3rem;
                        }
                    }
                    &:last-child{
                        &:after{
                            bottom: auto;
                            height: 10rem;
                        }
                    }
                }
            }
        }
    }
    .side-grafik{
        flex-wrap: wrap;
        .left-side, .right-side{
            flex-basis: 100%;
            max-width: 100%;
        }
        .left-side{
            margin-bottom: 1rem;
        }
    }
    .box-thumb-structure{
        &.purple{
            &:after{
                display: none;
            }
        }
    }
    .side-detail{
        flex-wrap: wrap;
        .column{
            flex-basis: 100% !important;
            max-width: 100% !important;
            margin-bottom: 2rem;
        }
    }
}