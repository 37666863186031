/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
@import "helper/sass-lib";
@import "helper/flexbox";

/* milligram
----------------------------------------------------------------------------------------------*/
@import "milligram/milligram";

/* pages
----------------------------------------------------------------------------------------------*/
@import "pages/general";
@import "pages/home";
@import "pages/about";
@import "pages/popup";
@import "pages/document";
@import "pages/bantuan";
@import "pages/rancangan";
@import "pages/information";
@import "pages/misc";

/* pages responsive desktop
----------------------------------------------------------------------------------------------*/
@import "pages_desktop/general";
@import "pages_desktop/home";

/* pages responsive tablet
----------------------------------------------------------------------------------------------*/
@import "pages_tablet/general";
@import "pages_tablet/home";
@import "pages_tablet/popup";
@import "pages_tablet/document";
@import "pages_tablet/rancangan";
@import "pages_tablet/bantuan";
@import "pages_tablet/misc";
@import "pages_tablet/about";
@import "pages_tablet/information";

/* pages responsive mobile
----------------------------------------------------------------------------------------------*/
@import "pages_mobile/general";
@import "pages_mobile/home";
@import "pages_mobile/about";
@import "pages_mobile/popup";
@import "pages_mobile/document";
@import "pages_mobile/rancangan";
@import "pages_mobile/bantuan";
@import "pages_mobile/information";
@import "pages_mobile/misc";