.side-grafik{
	display: flex;
	justify-content: space-between;

	.left-side{
		flex: 1 0 40%;
		max-width: 40%;
	}
	.right-side{
		flex: 1 0 55%;
		max-width: 55%;
		img{
			width: 100%;
		}
	}
	.highcharts-menu{
		li{
			margin-bottom: 0;
		}
		hr{
			margin: 0;
		}
	}
}

.box-blue-gradient{
	padding: 5.4rem 10rem 6.7rem;
	margin: 6rem -10rem;
	color: $color-initial;
	border-radius: 1.5rem;
	background: rgba(68,144,185,1);
	background: -moz-linear-gradient(-45deg, rgba(68,144,185,1) 0%, rgba(53,53,135,1) 50%, rgba(53,53,135,1) 100%);
	background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(68,144,185,1)), color-stop(50%, rgba(53,53,135,1)), color-stop(100%, rgba(53,53,135,1)));
	background: -webkit-linear-gradient(-45deg, rgba(68,144,185,1) 0%, rgba(53,53,135,1) 50%, rgba(53,53,135,1) 100%);
	background: -o-linear-gradient(-45deg, rgba(68,144,185,1) 0%, rgba(53,53,135,1) 50%, rgba(53,53,135,1) 100%);
	background: -ms-linear-gradient(-45deg, rgba(68,144,185,1) 0%, rgba(53,53,135,1) 50%, rgba(53,53,135,1) 100%);
	background: linear-gradient(135deg, rgba(68,144,185,1) 0%, rgba(53,53,135,1) 50%, rgba(53,53,135,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4490b9', endColorstr='#353587', GradientType=1 );

	h4{
		color: $color-initial;
		margin-bottom: 2.4rem;
	}
}

.side-line{
	padding-left: 3.7rem;
	border-left: .2rem solid $color-initial;
	font-size: 2.3rem;
	line-height: 3.8rem;
	font-weight: 300;
}

.box-thumb-structure{
	text-align: center;
	position: relative;
	margin-bottom: 2.3rem;
	// cursor: pointer;

	&:before{
		content:"";
		background: #353587;
		z-index: -1;
		border-radius: 1rem;
		@include position(absolute, 4rem 0 0);
	}
	&:after{
		content:"";
		width: .3rem;
		height: 1.6rem;
		margin: 0 auto;
		background: #6C6CB9;
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
	}
	figure{
		border: 1rem solid $color-initial;
		border-radius: 50%;
		display: inline-block;
		overflow: hidden;
		img{
			border-radius: 50%;
			width: 6.6rem;
			transition: .2s all ease-out;
		}
	}
	figcaption{
		color: $color-initial;
		padding: 2rem;

		span{
			display: block;
			font-size: 1.1rem;
			line-height: 1.3;
			font-weight: 700;
			color: rgba(255,255,255,.6);
			text-transform: uppercase;
		}
		h5{
			margin: 0;
			letter-spacing: 0;
		}
	}
	.detail-thumb{
		display: none;
	}

	// &:hover{
	// 	figure{
	// 		img{
	// 			transform: scale(1.05);
	// 		}
	// 	}
	// }

	&.purple{
		&:before{
			background: #6C6CB9;
		}
		&:after{
			top: auto;
			height: 1.8rem;
			bottom: calc(100% - 1rem);
		}
		figure{
			img{
				border: .3rem solid #6C6CB9;
			}
		}
	}
	&.blue-light{
		display: flex;
		background: #4CBED3;
		align-items: center;
		text-align: left;
		padding: 1rem;
		border-radius: 1rem;
		margin-left: 2rem;
		margin-bottom: 1rem;

		&:before{
			height:.3rem;
			bottom: 50%;
			top: auto;
			right: 100%;
			left: -1.5rem;
		}
		&:after{
			top: -1rem;
			right: auto;
			height: auto;
			left: -1.5rem;
			bottom: 0;
		}
		figure{
			border: none;
			flex: 1 0 4.2rem;
			max-width: 4.2rem;
			img{
				width: 4.2rem;
				border: none;
			}
		}
		figcaption{
			padding: 0 0 0 1rem;
			h5{
				font-size: 1.4rem;
			}
		}

		&:first-child{
			&:after{
				top: -2.5rem;
			}
		}
		&:last-child{
			&:after{
				bottom: 50%;
			}
		}
	}
}

.row-structure{
	.row{
		justify-content: center;

		&.sub-head-structure{
			position: relative;
			margin-bottom: 6rem;
			.column{
				position: relative;
				&:before{
					content:"";
					height: .3rem;
					background: #6C6CB9;
					@include position(absolute, -.8rem 0 null);
				}

				&:first-child{
					&:before{
						left: 50%;
					}
				}
				&:last-child{
					&:before{
						right: 50%;
					}
				}
			}
		}
	}
}