@media all and (max-width: 720px){
    .thumb-rounded{
        .column{
            flex-basis: 100% !important;
            max-width: 100% !important;
            margin-bottom: 2rem !important;
            > div, > div > a{
                display: flex;
                align-items: center;
            }
            figure{
                flex: 1 0 45%;
                max-width: 45%;
                img{
                    vertical-align: top;
                    border-radius: .6rem 0 0 .6rem;
                }
            }
            figcaption{
                padding: 1rem 1.5rem;
                h5{
                    font-size: 1.5rem;
                    line-height: 2rem;
                    max-height: 8rem;
                    overflow: hidden;
                }
                .button{
                    position: relative;
                    left: auto;
                    right: auto;
                    bottom: auto;
                }
            }
        }
    }
    .detail-pustaka{
        flex-wrap: wrap;
        .column{
            flex-basis: 100% !important;
            max-width: 100% !important;
            margin-bottom: 2rem;
        }
    }
    .share-information-back{
        &.row-center{
            // align-items: flex-start;
            flex-direction: row;
            .link-blue{
                margin-bottom: 1rem;
            }
        }
    }
    .thumb-rounded-gallery{
        .list{
            flex-basis: 100% !important;
            max-width: 100% !important;
            width: 100%;
        }
    }
    .filter-kamus{
        &.row{
            flex-direction: row;
            .column.column-7{
                flex: 0 0 20%;
                max-width: 20%;
                padding:0 2%;
            }
        }
    }
    .kamus-list{
        h5{
            font-size: 16px;
        }
    }
    .info-kliping{
        padding: 2rem;
    }
    .tema-peraturan{
        &.thumb-rounded{
            .column{
                > div, > div > a{
                    display: block;
                    // align-items: center;
                }
                figure{
                    flex: 0 0 100%;
                    max-width: 100%;
                    border-radius: .6rem .6rem 0 0 ;
                    img{
                        vertical-align: top;
                        border-radius: 0;
                    }
                }
                figcaption{
                    padding: 1rem 1.5rem;
                    h5{
                        font-size: 1.5rem;
                        line-height: 2rem;
                        max-height: 8rem;
                        overflow: hidden;
                    }
                    .button{
                        position: relative;
                        left: auto;
                        right: auto;
                        bottom: auto;
                    }
                }
            }
        }
    }
}