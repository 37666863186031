@media all and (max-width: 1300px){
    .banner-home{
        height: 50rem;
        display: flex;
        align-items: center;
        .slider-home{
            figure{
                height: 50rem;
            }
        }
    }
    .activity-home{
        padding: 4rem 0 12rem;
        .wrapper{
            padding: 0 3rem;
        }
        .side-title{
            align-items: center;
            .left-side-title{
                width: 60%;
            }
        }
    }
    .news-home{
        padding: 8rem 0 0;
    }
    .about-home{
        &:after{
            top: 10rem;
        }
        h1{
            font-size: 2rem;
            &.light{
                font-size: 2rem;
            }
        }
        .wrapper{
            padding-left: 3rem;
            padding-right: 3rem;
        }
        .text-about{
            padding-bottom: 9rem;
        }
    }
    .grafik-home{
        .wrapper{
            padding-left: 0;
        }
        figure{
            flex: 1 0 50%;
            max-width: 50%;
        }
        .text-grafik{
            flex: 1 0 45%;
            max-width: 45%;
        }
    }
    .survey-home{
        .wrapper{
            max-width: 100%;
            padding: 0 3rem;
        }
        .survey-result{
            .row{
                justify-content: space-between;
            }
            .column.column-50{
                flex-basis: 55%;
                max-width: 55%;
            }
            .column.column-45{
                flex-basis: 40%;
                max-width: 40%;
            }
        }
    }
    .related-link{
        padding-right: 3rem;
        .right-related-link{
            flex-basis: 73%;
            max-width: 73%;
        }
    }
    .tema-home{
        .slider-thema{
            margin-right: -20px;
        }
    }
    .infografis-home{
      .thumb-rounded{
        margin-left: -1.0rem;
        width: calc(100% + 2.0rem);
        .column{
          padding: 0 1.0rem;
        }
      }
    }
}

@media all and (max-width: 1020px){
    h4{
        font-size: 2rem;
        &.light{font-size: 2rem;}
    }

    .banner-home, .banner-home .slider-home figure{
        height: 68.8rem;
    }
    .banner-home{
        .text-banner{
            width: 100%;
            padding-left: 8rem;
            padding-right: 8rem;
        }
    }
    .box-search-bar{
        padding-bottom: 2rem;
    }

    .rules-home{
        .wrapper{
            flex-wrap: wrap;
        }
        .left-rules-home{
            border-right: none;
            flex-basis: 100%;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: .1rem solid rgba(54, 54, 137, 0.1);
            margin-bottom: 2.3rem;
            padding-right: 0;
            padding-bottom: 1rem;
            align-items: center;
            h4{
                margin-bottom: 0;
            }
        }
        .right-rules-home{
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
    .news-home{
        .news-list{
            flex-wrap: wrap;
            flex-direction: row;
            .column{
                flex-basis: 100%;
                max-width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
                > a{
                    flex: 1 0 25rem;
                    max-width: 25rem;
                    margin-right: 3.8rem;
                }
            }
            figure{
                flex: 1 0 25rem;
                max-width: 25rem;
                margin-bottom: 0;
            }
        }
    }
    .about-home{
        &:before{
            min-height: 0;
        }
        .text-about{
            padding: 16rem 0 5rem;
        }
    }
    .circle-survey{
        width: 22rem;
        height: 22rem;
        h2{
            font-size: 4.8rem;
            span.small{
                font-size: 2.1rem;
            }
        }
        p{
            font-size: 1.3rem;
        }
        .stars{
            li{
                width: 2rem;
                &.half {
                    &:after {background-size: 2rem;}
                }
            }
        }
    }
    .related-link{
        .right-related-link{
            flex-basis: 65%;
            max-width: 65%;;
        }
    }
    .select-search{
        select{
            flex: 1 0 25%;
            max-width: 25%;
        }
    }
    .infografis-home{
        .thumb-rounded{
            width: auto;
            margin-right: -30px;
            .column{
              width: 220px;
              flex: 0 0 220px !important;
              max-width: 220px !important;
                figcaption{
                    height: auto;
                    overflow: hidden;
                }
            }
        }
    }
    .grafik-home{
        padding: 10rem 0;
    }
    .survey-home{
        .survey-result{
            padding-left: 3rem;
            h6{
                margin: 0;
            }
            .lap-row{
                h3{
                    font-size: 20px;
                    line-height: 27px;
                }
            }
            .row{
                flex-direction: column;
                .column{
                    flex: 100%;
                    max-width: unset;
                    &.column-55{
                        margin-bottom: 2rem;
                    }
                    &.column-45{
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}