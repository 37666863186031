@media all and (max-width: 720px){
    header{
        .burger-nav{
            width: 6rem;
            height: 6rem;
        }
        .head-top{
            padding: 1rem 0;
            height: 6rem;
            .logo-1{width: 3rem;}
            .logo-2{width: 17rem;}
            .logo{
                a{
                    margin-right: .5rem;
                    img{vertical-align: top;}
                }
                img{
                    height: auto;
                }
            }
            .wrapper{
                align-items: center;
                height: 100%;
            }
        }
        .head-right{
            width: 100%;
            height: auto;
        }
        .head-bottom{
            top: 6rem;
            width: 100%;
            height: auto;
        }

        &.float{
            .overlay-head{
                top: 6rem;
            }
            .head-top{
                height: 6rem;
                .logo{
                    img{
                        height: auto;
                    }
                }
            }
            .burger-nav{
                width: 6rem;
                height: 6rem;
            }
            .head-bottom{
                height: auto;
            }
        }
    }
    h2{
        font-size: 2.8rem;
    }
    h3{
        font-size: 2.4rem;
    }
    h4{
        font-size: 1.8rem;
        &.light{font-size: 1.8rem;}
    }
    h5{
        &.bblack{
            font-size: 1.6rem;
        }
    }
    .std-content{
        p{
            line-height: 2.2rem;
        }
        td, th{
            padding: 1rem;
        }
    }

    .space-header{margin-top: 6rem;}
    .wrapper, .wrapper-middle, .wrapper-small, .wrapper-xsmall{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .section-content{
        padding-bottom: 5rem;
    }
    .banner{
        h3{font-size: 2rem;}
        figure{
            img{
                height: 15rem;
            }
        }
    }
    .side-title{
        flex-wrap: wrap;
        .left-side-title, .right-side-title{
            flex-basis: 100%;
            max-width: 100%;
            h4.light{font-size: 1.6rem;}
        }
    }
    .images-content, .box-blue-gradient{
        margin-left: 0;
        margin-right: 0;
    }
    .images-content{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .box-blue-gradient{
        padding: 3rem 2rem;
    }
    .breadcrumbs{
        ul{
            li{
                padding-left: 2rem;
                margin-right: .5rem;
                a{font-size: 1.3rem;}
            }
        }
    }
    .box-video{
        .btn-play-video{
            background-size: 3rem;
            background-position: center;
            font-size: 1.6rem;
        }
        iframe{
            height: auto;
        }
    }
    .button, button, input[type='button'], input[type='reset'], input[type='submit']{
        font-size: 1.3rem;
        padding: 0 2rem;
        &.button-blue-rounded, &.button-outline-red{
            font-size: 1.3rem;
        }
        &.button-create{
            font-size: 1.3rem;
            height: 4.5rem;
            line-height: 4.3rem;
            border-radius: 4.5rem;
            padding-left: 5.7rem;
            overflow: hidden;
            &:before,&:after{
                width: 4.5rem;
                height: 4.3rem;
            }
        }
        &.button-blue-light{
            height: 4rem;
            line-height: 4rem;
            font-size: 1.3rem;
            &.type2{
                font-size: 1.3rem;
            }
        }
    }
    .link-blue{font-size: 1.3rem;}
    // .pagination{
    //     a{
    //         &:nth-of-type(4){
    //             display: none;
    //         }
    //     }
    // }
    .box-form{
        input[type='email'], input[type='number'], input[type='password'], input[type='search'], input[type='tel'], input[type='text'], input[type='url'], textarea, select{
            padding: 0 2rem;
        }
        textarea {padding: 1rem 2rem;}
    }
    .input-file{
        width: 100% !important;
    }
    .box-form-rounded{
        .row{
            flex-wrap: wrap;
            .column{
                flex-basis: 100% !important;
                max-width: 100% !important;
            }
            &.row-center{
                align-items: flex-start;
            }
        }
        .button{
            width: 100%;
        }
        hr{
            margin: 1.5rem 0;
        }
    }
    .box-form-underline{
        .button{
            width: 100%;
        }
        .row{
            flex-wrap: wrap;
            .column{
                flex-basis: 100% !important;
                max-width: 100% !important;
            }
        }
    }
    .center-button{
        .button{
            margin: 0 0 1rem;
        }
    }
    .accordion-text{
        .list-acc-text{
            h5{
                font-size: 1.5rem;
            }
            p{
                font-size: 1.4rem;
                line-height: 2rem;
            }
            .btn-acc-text, .content-acc-text{
                padding-right: 3rem;
            }
        }
    }
    .checkbox-rounded{
        i{
            flex: 1 0 2rem;
            max-width: 2rem;
        }
        span{
            font-size: 1.4rem;
            line-height: 1.3;
        }
    }
    table{
        &.table-head-blue{
            th, td{
                display: flex;
                padding-top: .7rem;
                padding-bottom: .7rem;
                font-size: 1.4rem;
                &:before{
                    content: attr(data-title);
                    display: block;
                    flex: 1 0 50%;
                    max-width: 50%;
                    font-weight: 600;
                    color: #353587;
                    padding-right: 1rem;
                    text-align: left;
                }
            }
            tr{
                display: block;
                padding: 1.5rem 0;
            }
            thead{
                display: none;
            }
        }
    }
    .back-to-top{
        bottom: 9rem;
        right: 2rem;
        width: 4rem;
        height: 4rem;
    }
    .box-radio-btn{
        .radio-btn{
            flex: 1;
            margin-right: .5rem;
            i{
                flex: 1 0 1.5rem;
                max-width: 1.5rem;
                margin-right: .5rem;
                background-size: 200%;
            }
            span{
                font-size: 1.4rem;
            }
        }
    }

    footer{
        .foot-top{
            .row{
                flex-direction: row;
                .column{
                    &.column-18{
                        flex-basis: 50%;
                        max-width: 50%;
                    }
                    &.column-15{
                        flex-basis: 100%;
                        max-width: 100%;
                    }
                }
            }
            .app-foot{
                display: flex;
                a{
                    margin-right: 1rem;
                }
            }
        }
        .foot-bottom{
            .row{
                align-items: normal;
                flex-wrap: wrap;
                .column{
                    flex-basis: 100%;
                    max-width: 100%;
                    text-align: left;
                    margin-bottom: 10px;
                    &.column-60, &.column-40{
                    }
                    &.column-60{
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
    .accordion-select{
        .list-acc{
            .acc-select{
                .box-form-underline{
                    .row{
                        flex-flow: row;
                        flex-wrap: wrap;
                        .column-28{
                            flex-basis: 30% !important;
                            max-width: 30% !important;
                            margin-right: 10px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                    select, label{
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .share-only{
        .column.column-30{
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

@media all and (max-width: 550px){
    .accordion-select {
        .list-acc {
            .acc-select {
                .box-form-underline {
                    padding: 0 10px;
                    .row {
                        width: 100%;
                        .column-28{
                            flex-basis: 100% !important;
                            max-width: 100% !important;
                            margin-right:0px;
                            margin-bottom: 10px;
                            label {display: block; width: 100%; margin: 0;}
                            select {
                                height: 4rem; line-height: 3.8rem;
                            }
                        }
                    }
                }
            }
        }
    }


}

