@media all and (max-width: 1300px){
    .box-img-bg{
        margin-bottom: 4rem;
    }
    .thumb-consultation{
        .column{
            h5{
                font-size: 1.7rem;
            }
        }
    }
}

@media all and (max-width: 1020px){
    .box-img-bg{
        padding-left: 5.6rem;
        padding-right: 5.6rem;
    }
    .thumb-consultation{
        flex-wrap: wrap;
        .column{
            figure{
                margin-bottom: 0;
                margin-right: 2rem;
                align-self: flex-end;
                height: auto;
                flex:1 0 18rem;
                max-width: 18rem;
            }
            figcaption{
                text-align: left;
            }
            > div{
                padding: 0 2rem;
                display: flex;
                align-items: center;
            }
            &.column-25{
                flex-basis: 100%;
                max-width: 100%;
                margin-bottom: 2rem;
            }
        }
    }
    .faq-section{
        .wrapper-small{
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
}