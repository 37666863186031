.box-img-bg{
	position: relative;
	min-height: 44rem;
	padding: 7.3rem 7rem;
	color: #9393CE;
	margin-bottom: 6rem;

	h4{
		color: $color-initial;
	}
	p{
		font-size: 1.5rem;
		line-height: 2.4rem;
	}
	figure{
		z-index: 1;
		@include position(absolute, 0);

		&:before{
			content:"";
			@include position(absolute, 0);
			border-radius: .8rem;
			background: rgba(53,53,135,1);
			background: -moz-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 37%, rgba(53,53,135,0.4) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(53,53,135,1)), color-stop(37%, rgba(53,53,135,1)), color-stop(100%, rgba(53,53,135,0.4)));
			background: -webkit-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 37%, rgba(53,53,135,0.4) 100%);
			background: -o-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 37%, rgba(53,53,135,0.4) 100%);
			background: -ms-linear-gradient(-45deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 37%, rgba(53,53,135,0.4) 100%);
			background: linear-gradient(135deg, rgba(53,53,135,1) 0%, rgba(53,53,135,1) 37%, rgba(53,53,135,0.4) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#353587', endColorstr='#353587', GradientType=1 );
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: .8rem;
		}
	}
	figcaption{
		position: relative;
		z-index: 2;
		.inner-text{
			width: 50%;
		}
	}
}

.thumb-consultation{
	.column{
		border-radius: .8rem;
		> div{
			border-radius: .8rem;
			box-shadow: 0 .3rem .6rem 0 rgba(0,0,0,0.16);
			padding: 2rem 3rem;
			height: 100%;
			transition: .2s all ease-out;
		}
		h5{
			font-size: 2rem;
			color: #111111;
			font-weight: 700;
			margin-bottom: 1rem;
		}
		p{
			font-size: 1.5rem;
			line-height: 2.2rem;
			color: #818181;
		}
		figure{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 2rem;
			height: 20rem;
		}
		figcaption{
			text-align: center;
		}

		&:hover{
			> div{
				box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.16);
			}
		}
	}
}

.thumb-consult{
	.column{
		position: relative;
		padding-top: 4.5rem;
		margin-bottom: 6rem;

		hr{
			border-color: #E0E0E0;
			margin: 1.7rem 0;
		}
		h5{
			font-size: 1.8rem;
			font-weight: 700;
			color: #111111;
			line-height: 2.4rem;
		}
		p{
			font-size: 1.5rem;
			line-height: 2.2rem;
			color: #111111;
		}
		figure{
			width: 9rem;
			height: 9rem;
			padding: .4rem;
			border-radius: 50%;
			margin-left: -4.5rem;
			@include position(absolute, 0 null null 50%);
			background: rgba(65,121,174,1);
			background: -moz-linear-gradient(-45deg, rgba(65,121,174,1) 0%, rgba(54,57,139,1) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(65,121,174,1)), color-stop(100%, rgba(54,57,139,1)));
			background: -webkit-linear-gradient(-45deg, rgba(65,121,174,1) 0%, rgba(54,57,139,1) 100%);
			background: -o-linear-gradient(-45deg, rgba(65,121,174,1) 0%, rgba(54,57,139,1) 100%);
			background: -ms-linear-gradient(-45deg, rgba(65,121,174,1) 0%, rgba(54,57,139,1) 100%);
			background: linear-gradient(135deg, rgba(65,121,174,1) 0%, rgba(54,57,139,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4179ae', endColorstr='#36398b', GradientType=1 );
			img{
				border-radius: 50%;
			}
		}
		.text-thumbnail{
			border-radius: .6rem;
			padding: 6.5rem 3rem 3rem;
			box-shadow: 0 0 .6rem 0 rgba(0,0,0,.16);
			height: 100%;

			label{
				font-size: 1.2rem;
				font-weight: 600;
				color: #818181;
				margin-bottom: .7rem;
			}
		}
		.side-icon{
			align-items: flex-start;
			span{
				font-size: 1.5rem;
				line-height: 2.2rem;
				color: #111111;
			}
		}
		.button{
			margin-top: 1rem;
		}
	}
}