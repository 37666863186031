@media all and (max-width: 1300px){
    .popup{
        .inner-popup{
            width: 90%;
            max-width: 93rem;
        }
    }
}

@media all and (max-width: 1020px){
    .popup{
        .inner-popup{
            padding: 3rem;
            &.in-full-img{
                max-width: 90%;
            }
        }
    }
}